import { Button, Divider, Group, LoadingOverlay, Modal, Text } from "@mantine/core";
import { IconEdit } from "@tabler/icons-react";
import React from "react";
import { useNavigate } from "react-router";
import { DistributorApi, TierPriceApi } from "../../apis";
import { Dialog } from "../../utils/confirm-modal";
import HttpHandler from "../../utils/http-handler";
import { message } from "../../utils/message";
import { PageLayout } from "../layout";
import { usePermission } from "../permission";
import { ColumnType, ITableRef, Table } from "../table";
import { TierPriceForm } from "./tier-price-form";

export function TierPriceList() {
  const [filters] = React.useState({ query: "" });
  const [action, setAction] = React.useState<string[]>([]);
  const ref = React.useRef<ITableRef>(null);
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();
  const ERP_REF_TIER = usePermission("ERP_REF_TIER");

  const columns = useHeader({
    actionAccess: ERP_REF_TIER,
    onClick: (key, item) => {
      switch (key) {
        case "edit": {
          setAction(["update", item]);
          break;
        }

        case "remove": {
          Dialog.confirm("Та үүнийг устгахдаа итгэлтэй байна уу?", async (key) => {
            switch (key) {
              case "confirm": {
                setLoading(true);
                try {
                  let res = await DistributorApi.remove(item.id);
                  if (res.success) {
                    setTimeout(() => {
                      ref.current?.reload();
                    }, 200);
                  }
                  message.success("Амжилттай дистрибютрийг устгалаа!");
                  setLoading(false);
                } catch (err) {
                  setLoading(false);
                  message.error((err as HttpHandler)?.message!);
                }
                break;
              }

              default:
            }
          });
          break;
        }
      }
    },
  });

  const onCancel = async () => {
    setAction([]);
  };

  const reloadTable = () => {
    setTimeout(() => {
      ref.current?.reload();
    }, 200);
  };

  if (loading) return <LoadingOverlay visible={loading} />;

  return (
    <>
      <PageLayout
        title="Барааны стандарт үнийн жагсаалт"
        subTitle="Барааны стандарт үнийн жагсаалт, засварлах"
        extra={[
          <Button key={1} variant="default" onClick={() => navigate("/reference")}>
            Буцах
          </Button>,
        ]}>
        <Divider my="lg" />
        {ERP_REF_TIER.isView ? (
          <Table ref={ref} name="distributor.list" columns={columns} filters={filters} loadData={(data) => TierPriceApi.list(data!)} />
        ) : (
          ERP_REF_TIER.accessDenied()
        )}
      </PageLayout>
      <Modal opened={!!action[0]} onClose={() => setAction([])} withCloseButton={false} centered>
        <TierPriceForm reload={() => reloadTable()} action={action} onClose={onCancel} />
      </Modal>
    </>
  );
}

type HeaderProps = {
  onClick: (key: string, record: any) => void;
  actionAccess: {
    isDelete: boolean;
    isEdit: boolean;
    isView: boolean;
  };
};

const useHeader = ({ onClick, actionAccess }: HeaderProps): ColumnType<any>[] => [
  {
    title: "#",
    render: (_r, index) => <Text size="sm">{index + 1}</Text>,
    width: "30px",
  },
  {
    title: "Tier",
    dataIndex: "name",
    render: (record) => {
      return (
        <Text size="sm" w="max-content">
          {record.tierNo || "-"}
        </Text>
      );
    },
  },
  {
    title: "Нэр",
    dataIndex: "isPublic",
    render: (record) => {
      return (
        <Text size="sm" w="max-content">
          {record?.name || "-"}
        </Text>
      );
    },
  },

  {
    title: "Үйлдэл",
    align: "right",
    render: (record) => {
      return (
        <Group>
          <Button
            variant="light"
            radius={100}
            w={35}
            h={35}
            p={0}
            onClick={() => onClick("edit", record)}
            hidden={!actionAccess.isEdit}>
            <IconEdit />
          </Button>
        </Group>
      );
    },
  },
];
