/* eslint-disable react-hooks/exhaustive-deps */
import {
  Badge,
  Box,
  Center,
  CloseButton,
  Divider,
  Flex,
  Grid,
  Group,
  Input,
  LoadingOverlay,
  Paper,
  Popover,
  Space,
  Text,
  TextInput,
  UnstyledButton,
} from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import { randomId, useClickOutside, useDebouncedState } from "@mantine/hooks";
import { IconInputSearch, IconPlus, IconSearch } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import useSwr from "swr";
import * as yup from "yup";
import { PriceGroupApi } from "../../apis";
import PriceGroupSelectList from "../change-standard-price/price-group-select-list";
import ValuesVariantsList from "../change-standard-price/values-variants-list";
import { Form } from "../form";
import { DatePickerField } from "../form/datepicker-field";
import { TextField } from "../form/text-field";
import { useSelector } from "react-redux";
import { IAuth } from "../../interfaces/IAuth";
import { dateFormat } from "../../utils/date";
import dayjs from "dayjs";

const schema = yup.object().shape({
  type: yup.string().required("Заавал бөглөнө!").nullable(),
  name: yup.string().max(255, "Хэт урт байна!").required("Заавал бөглөнө!").nullable(),
  description: yup.string().max(255, "Хэт урт байна!").required("Заавал бөглөнө!").nullable(),
  startDate: yup.date().typeError("Заавал бөглөнө!").required("Заавал бөглөнө!").nullable(),
  endDate: yup.date().typeError("Заавал бөглөнө!").required("Заавал бөглөнө!").nullable(),
  confirm: yup.boolean().optional(),
  variants: yup
    .array()
    .of(
      yup.object().shape({
        variantId: yup.string().nullable().required("Заавал бөглөнө!"),
        standardPrice: yup.number().typeError("Заавал бөглөнө!").min(0, "Заавал бөглөнө!").nullable().required("Заавал бөглөнө!"),
        tier1Price: yup.object().shape({
          isSet: yup.boolean(),
          value: yup
            .number()
            .nullable()
            .when("isSet", {
              is: true,
              then: (schema) => schema.min(0, "Заавал бөглөнө!").typeError("Заавал бөглөнө!").nullable().required("Заавал бөглөнө!"),
            })
            .optional(),
        }),
        tier2Price: yup.object().shape({
          isSet: yup.boolean(),
          value: yup
            .number()
            .nullable()
            .when("isSet", {
              is: true,
              then: (schema) => schema.min(0, "Заавал бөглөнө!").typeError("Заавал бөглөнө!").nullable().required("Заавал бөглөнө!"),
            })
            .optional(),
        }),
        tier3Price: yup.object().shape({
          isSet: yup.boolean(),
          value: yup
            .number()
            .nullable()
            .when("isSet", {
              is: true,
              then: (schema) => schema.min(0, "Заавал бөглөнө!").typeError("Заавал бөглөнө!").nullable().required("Заавал бөглөнө!"),
            })
            .optional(),
        }),
        tier4Price: yup.object().shape({
          isSet: yup.boolean(),
          value: yup
            .number()
            .nullable()
            .when("isSet", {
              is: true,
              then: (schema) => schema.min(0, "Заавал бөглөнө!").typeError("Заавал бөглөнө!").nullable().required("Заавал бөглөнө!"),
            })
            .optional(),
        }),
        tier5Price: yup.object().shape({
          isSet: yup.boolean(),
          value: yup
            .number()
            .nullable()
            .when("isSet", {
              is: true,
              then: (schema) => schema.min(0, "Заавал бөглөнө!").typeError("Заавал бөглөнө!").nullable().required("Заавал бөглөнө!"),
            })
            .optional(),
        }),
        tier6Price: yup.object().shape({
          isSet: yup.boolean(),
          value: yup
            .number()
            .nullable()
            .when("isSet", {
              is: true,
              then: (schema) => schema.min(0, "Заавал бөглөнө!").typeError("Заавал бөглөнө!").nullable().required("Заавал бөглөнө!"),
            })
            .optional(),
        }),
        tier7Price: yup.object().shape({
          isSet: yup.boolean(),
          value: yup
            .number()
            .nullable()
            .when("isSet", {
              is: true,
              then: (schema) => schema.min(0, "Заавал бөглөнө!").typeError("Заавал бөглөнө!").nullable().required("Заавал бөглөнө!"),
            })
            .optional(),
        }),
        tier8Price: yup.object().shape({
          isSet: yup.boolean(),
          value: yup
            .number()
            .nullable()
            .when("isSet", {
              is: true,
              then: (schema) => schema.min(0, "Заавал бөглөнө!").typeError("Заавал бөглөнө!").nullable().required("Заавал бөглөнө!"),
            })
            .optional(),
        }),
        tier9Price: yup.object().shape({
          isSet: yup.boolean(),
          value: yup
            .number()
            .nullable()
            .when("isSet", {
              is: true,
              then: (schema) => schema.min(0, "Заавал бөглөнө!").typeError("Заавал бөглөнө!").nullable().required("Заавал бөглөнө!"),
            })
            .optional(),
        }),
        tier10Price: yup.object().shape({
          isSet: yup.boolean(),
          value: yup
            .number()
            .nullable()
            .when("isSet", {
              is: true,
              then: (schema) => schema.min(0, "Заавал бөглөнө!").typeError("Заавал бөглөнө!").nullable().required("Заавал бөглөнө!"),
            })
            .optional(),
        }),
      }),
    )
    .optional(),
});

type ITypePriceGroup = {
  refCode?: string;
  type: string | null | undefined;
  name: string | null | undefined;
  description: string | null | undefined;
  startDate: string | null | undefined;
  endDate: string | undefined | null;
  variants: {
    variantId: string | undefined | null;
    tier1Price: {
      value: number | undefined | null;
      isSet: boolean;
    };
    tier2Price: {
      value: number | undefined | null;
      isSet: boolean;
    };
    tier3Price: {
      value: number | undefined | null;
      isSet: boolean;
    };
    tier4Price: {
      value: number | undefined | null;
      isSet: boolean;
    };
    tier5Price: {
      value: number | undefined | null;
      isSet: boolean;
    };
    tier6Price: {
      value: number | undefined | null;
      isSet: boolean;
    };
    tier7Price: {
      value: number | undefined | null;
      isSet: boolean;
    };
    tier8Price: {
      value: number | undefined | null;
      isSet: boolean;
    };
    tier9Price: {
      value: number | undefined | null;
      isSet: boolean;
    };
    tier10Price: {
      value: number | undefined | null;
      isSet: boolean;
    };
  }[];
  confirm: boolean;
};

type ITypeCategoiesList = {
  id: string;
  type: string;
  name: string;
};

type ITypeSelectCategories = { value: string; label: string };

function ChangeForm({ chagneFormRed, data: standardItemData }: { chagneFormRed: any; data?: any }) {
  const { theme } = useStyles();
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);
  const [selectCategories, setSelectCategories] = useState<ITypeSelectCategories[]>([]);
  const [query, setQuery] = useState<string>("");
  const [queryDelay, setQueryDelay] = useDebouncedState<string>("", 800);
  const [popoverOpened, setPopoverOpened] = useState<boolean>(false);
  const ref: any = useClickOutside<any>(() => setPopoverOpened(false));

  const { data: categoiesList = [] } = useSwr<ITypeCategoiesList[]>(`/api/price_group/category`, async () => await PriceGroupApi.category(), {
    fallback: [
      {
        id: undefined,
        type: undefined,
        name: undefined,
      },
    ],
  });

  const { data: variantList = [], isLoading: variantIsLoading } = useSwr<ITypeCategoiesList[]>(
    `/api/price_group/variant/select?query=${JSON.stringify(queryDelay || null)}&${JSON.stringify({
      categories: [
        ...selectCategories.map((item: ITypeSelectCategories) => {
          return item.value;
        }),
      ],
    })}`,
    async () =>
      (query?.length || 0) < 100
        ? await PriceGroupApi.variantSelect({
            query: queryDelay,
            type: "GROUP",
            categories: [
              ...selectCategories.map((item: ITypeSelectCategories) => {
                return item.value;
              }),
            ],
          })
        : null,
    {
      fallback: [
        {
          id: undefined,
          type: undefined,
          name: undefined,
        },
      ],
    },
  );

  const tiers: any = {
    tier1Price: 1,
    tier2Price: 2,
    tier3Price: 3,
    tier4Price: 4,
    tier5Price: 5,
    tier6Price: 6,
    tier7Price: 7,
    tier8Price: 8,
    tier9Price: 9,
    tier10Price: 10,
  };

  const mapData = (arr: any) => {
    let returnObj = {};

    Object.keys(tiers)
      .map((e) => {
        const tier = arr.find((a: any) => a.tierNo === tiers[e]);
        return {
          [e]: {
            value: tier.price,
            isSet: tier.isSet,
          },
        };
      })
      .forEach((e) => {
        returnObj = {
          ...returnObj,
          ...e,
        };
      });

    return returnObj;
  };

  const [updateData] = useState<ITypePriceGroup>({
    type: "GROUP",
    name: standardItemData?.name ?? null,
    description: standardItemData?.description ?? null,
    startDate: standardItemData?.startDate ?? null,
    endDate: standardItemData?.endDate ?? null,
    variants:
      (standardItemData?.variants?.map((item: any) => {
        return {
          ...item,
          name: item?.name,
          ...mapData(item.tiers),
        };
      }) ||
        []) ??
      [],
    confirm: false,
  });

  const [newData] = useState<ITypePriceGroup>({
    type: "GROUP",
    name: undefined,
    description: undefined,
    startDate: dayjs(new Date()).format(),
    endDate: dayjs(new Date()).format(),
    variants: [],
    confirm: false,
  });

  useEffect(() => {
    setQueryDelay(query);
  }, [query]);

  return (
    <Form ref={chagneFormRed} onSubmit={(e) => e} initialValues={standardItemData !== undefined ? updateData : newData} validationSchema={schema}>
      {({ values, setFieldValue }) => {
        return (
          <div>
            <Divider my={"lg"} />
            <Grid>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <Input.Wrapper label="Үнийн код">
                  <Input value={standardItemData?.refCode ?? "Авто үүснэ"} disabled />
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <Input.Wrapper label="Статус" required>
                  <Flex mt="xs">
                    <Badge>{standardItemData?.priceGroupStatus ?? "Идэвхгүй"}</Badge>
                  </Flex>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <Input.Wrapper label="Бүртгэсэн" required>
                  <Input
                    value={`${user?.lastName ? user?.lastName[0] + "." : ""} ${user?.firstName || "-"}, ${
                      standardItemData?.createdAt && dateFormat(standardItemData.createdAt ?? "Авто үүснэ")
                    }`}
                    disabled
                  />
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <TextField name="name" placeholder="Үнийн өөрчлөлтийн нэр" label="Үнийн өөрчлөлтийн нэр" required />
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <TextField name="description" placeholder="Тайлбар оруулах" label="Тайлбар" required />
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <TextInput placeholder="" value={values?.variants?.length || 0} label="Үнэ өөрчлөгдсөн барааны тоо" disabled required />
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <DatePickerField minDate={new Date()} showTime name="startDate" placeholder="Огноо оруулах" label="Өөрчлөлт эхлэх огноо" clearable />
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                <DatePickerField
                  disabled={!values?.startDate}
                  minDate={new Date(values?.startDate) || new Date()}
                  showTime
                  name="endDate"
                  placeholder="Дуусах огноо, цаг"
                  label="Дуусах огноо, цаг"
                  clearable
                />
              </Grid.Col>
              <Grid.Col>
                <Input.Wrapper label="Үнийн өөрчлөлтийг оруулах:" required>
                  <Paper>
                    <Paper withBorder mb={"xs"}>
                      <Group align="center" px={"xs"} w={"100%"} mih={"40px"}>
                        {categoiesList?.filter((item2: ITypeCategoiesList) => !selectCategories.some((som: ITypeSelectCategories) => som.value === item2.id))
                          .length > 0 ? (
                          <>
                            <IconInputSearch size={"1.2rem"} color={theme.colors.gray[5]} stroke={1.4} />
                            {categoiesList
                              ?.filter((item2: any) => !selectCategories.some((som: ITypeSelectCategories) => som.value === item2.id))
                              ?.map((item: ITypeCategoiesList, index: number) => {
                                return (
                                  <UnstyledButton
                                    key={index}
                                    onClick={() => {
                                      if (selectCategories.some((item2: ITypeSelectCategories) => item2.value === item.id))
                                        setSelectCategories(selectCategories.filter((item2: ITypeSelectCategories) => item2.value !== item.id));
                                      else
                                        setSelectCategories([
                                          ...selectCategories,
                                          {
                                            label: item.name,
                                            value: item.id,
                                          },
                                        ]);
                                    }}>
                                    <Text>
                                      <Flex>
                                        <Badge variant="dot">
                                          <Flex align={"center"} gap={"0.2rem"}>
                                            {item.name} <IconPlus size={"0.8rem"} />
                                          </Flex>
                                        </Badge>
                                      </Flex>
                                    </Text>
                                  </UnstyledButton>
                                );
                              })}
                          </>
                        ) : (
                          <Center w={"100%"}>
                            <Text fz={"xs"} c="gray">
                              Ангилал байхгүй
                            </Text>
                          </Center>
                        )}
                      </Group>
                    </Paper>
                    <Box>
                      <Popover
                        opened={popoverOpened}
                        position="bottom"
                        width="target"
                        transitionProps={{ duration: 150, exitDuration: 150, transition: "pop" }}>
                        <Popover.Target>
                          <div>
                            <TextInput
                              name={randomId()}
                              maxLength={101}
                              onFocusCapture={() => setPopoverOpened(true)}
                              error={query?.length > 100 ? "Хэт урт байна." : false}
                              value={query}
                              onChange={(e) => {
                                setQuery(e.target.value);
                              }}
                              leftSectionWidth={32 + (115 * selectCategories?.length || 0)}
                              rightSection={
                                <CloseButton
                                  onClick={() => {
                                    setSelectCategories([]);
                                    setQuery("");
                                  }}
                                  size={"xs"}
                                  variant="transparent"
                                />
                              }
                              leftSection={
                                <Flex style={{ zIndex: 100 }} pos={"absolute"} align={"center"} justify={"start"} w={"100%"} pl={"xs"} gap={"xs"} bg={""}>
                                  <IconSearch size={"1.2rem"} />
                                  {selectCategories?.length > 0 && (
                                    <Flex align={"center"} gap={"xs"}>
                                      {selectCategories?.map((item3: ITypeSelectCategories, index: number) => {
                                        return (
                                          <Badge size="xs" key={index} maw={"200px"} w={100} variant="outline">
                                            <Text> {item3.label.length > 7 ? item3.label.substring(0, 8) + "..." : item3.label}</Text>
                                          </Badge>
                                        );
                                      })}
                                    </Flex>
                                  )}
                                </Flex>
                              }
                              placeholder="Хайх..."
                            />
                          </div>
                        </Popover.Target>
                        <Popover.Dropdown bg={"transparent"} p={0}>
                          <Paper shadow="xl" p={"lg"} w={"50rem"} ref={ref}>
                            <Flex align="center" mb={"xs"} w={"100%"} justify={"space-between"}>
                              <Text fw={500} fz={"xs"}>
                                Хайлтын үр дүн: (
                                {variantList.filter((item1: any) => !values?.variants?.some((item2: any) => item2.id === item1.id))?.length || 0})
                              </Text>
                              <CloseButton onClick={() => setPopoverOpened(false)} variant="transparent" />
                            </Flex>
                            <Box pos={"relative"} style={{ height: "250px", overflowY: "auto", overflowX: "hidden" }}>
                              <PriceGroupSelectList values={values} setFieldValue={setFieldValue} variantList={variantList} />
                              <LoadingOverlay visible={variantIsLoading} />
                            </Box>
                          </Paper>
                        </Popover.Dropdown>
                      </Popover>
                    </Box>
                    <Space h={"xs"} />
                    <Paper bg="white" pos={"relative"}>
                      <LoadingOverlay visible={variantIsLoading} />
                      <ValuesVariantsList variants={values?.variants || []} setFieldValue={setFieldValue} />
                    </Paper>
                  </Paper>
                </Input.Wrapper>
              </Grid.Col>
            </Grid>
          </div>
        );
      }}
    </Form>
  );
}

const useStyles = createStyles({});

export default ChangeForm;
