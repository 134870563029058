import { Avatar, FileButton, Flex, LoadingOverlay, Paper, Text } from "@mantine/core";
import { IconUpload } from "@tabler/icons-react";
import React from "react";
import { useField } from ".";
import { MediaApi } from "../../apis";

export type IFile = {
  uploading: boolean;
  url?: string;
  file: Blob | null;
};

type Props = {
  value?: string | undefined | null;
  error?: string;
  onChange?: (file: IFile) => void;
  disabled?: any;
};

function ImageUpload({ onChange, error, value, disabled }: Props) {
  const [file, setFile] = React.useState<IFile>();
  const resetRef = React.useRef<() => void>(null);

  const onFileUpload = (blob: Blob | null) => {
    if (!blob) return;

    (async () => {
      try {
        const form = new FormData();
        form.append("file", blob);

        const res = await MediaApi.uploadImage(form);

        if (onChange)
          onChange({
            file: blob,
            url: res.url,
            uploading: false,
          });

        setFile({
          file: blob,
          url: res.url,
          uploading: false,
        });
      } catch (err) {
        console.log(err);
      }
    })();

    setFile({
      file: blob,
      uploading: true,
    });
  };

  React.useEffect(() => {
    value &&
      setFile({
        file: null,
        url: value,
        uploading: false,
      });
  }, [value]);

  return (
    <>
      <Paper withBorder style={{ borderColor: error && "red" }}>
        <FileButton resetRef={resetRef} onChange={onFileUpload} accept="image/png,image/jpeg" disabled={disabled}>
          {(props) => {
            if (file)
              return (
                <Avatar size={320} w={"100%"} src={file.file ? URL.createObjectURL(file.file) : file.url} alt="Random unsplash image" radius={0} {...props}>
                  <LoadingOverlay visible={file.uploading} opacity={0.3} loaderProps={{ size: "sm" }} />
                  <Flex direction="column" align="center" gap={5}>
                    <IconUpload />
                    <Text fw={500} size={"xs"}>
                      Хуулах
                    </Text>
                  </Flex>
                </Avatar>
              );
            else
              return (
                <Avatar color={error && "red"} size={320} w={"100%"} alt="Random unsplash image" radius={0} {...props}>
                  <Flex direction="column" align="center" gap={5}>
                    <IconUpload />
                    <Text fw={500} size={"xs"}>
                      Хуулах
                    </Text>
                  </Flex>
                </Avatar>
              );
          }}
        </FileButton>
      </Paper>
    </>
  );
}

type Props2 = {
  name: string;
  label?: string;
  disabled?: boolean;
};

export function ImageFieldProduct({ name, label, disabled }: Props2) {
  const { value, error, onChange } = useField(name);

  return <ImageUpload disabled={disabled} value={value} onChange={(file: IFile) => onChange(file.url)} error={error} />;
}
