import { Tabs, Text } from "@mantine/core";
import { IconTruck, IconTruckLoading } from "@tabler/icons-react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { SuppApi } from "../../apis";
import { PageLayout } from "../../components/layout";
import { usePermission } from "../../components/permission";
import { SuppTable } from "../../components/suppTable";
import { IPubState, updateSuppTabKey } from "../../store/pubState";
import { message } from "../../utils/message";
import qs from "qs";

export const SuppGoods = () => {
  const breadcrumbs = useBreadcrumb();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { supptabKey } = useSelector((state: { pubState: IPubState }) => state.pubState);
  const ERP_STORE = usePermission("ERP_STORE");

  const onClick = (item: any, filter: Record<string, any>) => {
    const filterString = qs.stringify(filter);
    if (ERP_STORE.hasAccess) navigate(`/supp/${item.id}?${filterString}`);
    else message.error(ERP_STORE.text);
  };

  return (
    <PageLayout title="Нийлүүлэгчийн бараа" subTitle="DeHUB Network-р борлуулж буй нийлүүлэгчдийн бараа үйлчилгээ" breadcrumb={breadcrumbs}>
      <Tabs
        value={supptabKey}
        onChange={(e: string | null) => {
          if (e) dispatch(updateSuppTabKey(e));
        }}>
        <Tabs.List>
          <Tabs.Tab value={"our-supp"} leftSection={<IconTruckLoading size="22px" />}>
            Манай гэрээт
          </Tabs.Tab>
          <Tabs.Tab value="other-supp" leftSection={<IconTruck size="22px" />}>
            Манай гэрээт биш
          </Tabs.Tab>
        </Tabs.List>
        <Text fw={500} py={"md"} fz={"lg"}>
          Нийлүүлэгчид
        </Text>
        <Tabs.Panel value="our-supp">
          {ERP_STORE.hasAccess ? (
            <SuppTable onClick={onClick} name="our-supplier.list" filters={{ isContracted: true }} loadData={(data) => SuppApi.supplier(data!)} />
          ) : (
            ERP_STORE.accessDenied()
          )}
        </Tabs.Panel>
        <Tabs.Panel value="other-supp">
          {ERP_STORE.hasAccess ? (
            <SuppTable onClick={onClick} name="other.supplier.lists" filters={{ isContracted: false }} loadData={(data) => SuppApi.supplier(data!)} />
          ) : (
            ERP_STORE.accessDenied()
          )}
        </Tabs.Panel>
      </Tabs>
    </PageLayout>
  );
};

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    label: "Нийлүүлэгчийн бараа",
  },
];
