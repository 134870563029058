import { Anchor, Avatar, Box, Button, Divider, Flex, Grid, Modal, Paper, Text } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import { IconBoxModelOff, IconId, IconMail, IconPhone, IconUserCircle } from "@tabler/icons-react";
import { useRef, useState } from "react";
import { useParams } from "react-router";
import useSWR from "swr";
import { SuppApi } from "../../apis";
import { PageLayout } from "../../components/layout";
import { usePermission } from "../../components/permission";
import { ProductTable } from "../../components/productTable";
import { SuppFetchForm } from "../../components/supp/supp-fetch-form";
import HttpHandler from "../../utils/http-handler";
import { message } from "../../utils/message";

export const DetailsSupp = () => {
  const breadcrumbs = useBreadcrumb();
  const suppTableRef = useRef<any>(null);
  const [action, setAction] = useState<any[]>([]);
  const { theme } = useStyle();
  const params = useParams();
  const ERP_STORE = usePermission("ERP_STORE");
  const ERP_STORE_FETCH = usePermission("ERP_STORE_FETCH");

  const { data } = useSWR(ERP_STORE.hasAccess && `/erp/store/supplier/${params.id}`, async () => {
    if (!!params.id && ERP_STORE.hasAccess) {
      try {
        let res = await SuppApi.supp_get(`${params.id}`);
        return res;
      } catch (err) {
        message.error((err as HttpHandler)?.message!);
        return err;
      }
    } else return null;
  });

  const onClick = (key: string, record: any) => {
    setAction(["fetch", record]);
  };

  return (
    <PageLayout title="Нийлүүлэгчийн бараа" subTitle="DeHUB Network-р борлуулж буй нийлүүлэгчдийн бараа үйлчилгээ" breadcrumb={breadcrumbs}>
      {ERP_STORE.hasAccess ? (
        <>
          <Grid>
            <Grid.Col span={{ xs: 12, sm: 12, md: 12, lg: 4, xl: 3 }}>
              <Box
                h="100%"
                sx={(theme) => ({
                  border: `1px solid  ${theme.colors.gray[4]}`,
                  borderRadius: theme.radius.md,
                })}
                px={20}
                py={16}>
                <Flex align="center" direction="column" gap="md" justify="center">
                  <Box sx={{ position: "relative" }}>
                    <Avatar color={theme.colors.violet[6]} variant="gradient" size={90} src={data?.supplier?.logo ?? ""} />
                  </Box>
                  <Text fw={600} fz="xl" c={theme.colors.violet[6]}>
                    {data?.supplier?.profileName}
                  </Text>
                  <Text fw={700} fz="sm" c="gray">
                    {data?.supplier?.partner?.businessName}
                  </Text>

                  <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 600 } }} w="100%" label="Холбоо барих" />
                  <Flex direction="column" gap="xs" justify="start" align="start" w="100%">
                    <Flex gap="sm">
                      <IconMail color={theme.colors.violet[6]} />
                      <Text fw={500} c="gray">
                        {data?.supplier?.partner?.email ?? "example@mail.com"}
                      </Text>
                    </Flex>
                    <Flex gap="sm" justify="start" align="start" w="100%">
                      <IconPhone color={theme.colors.violet[6]} />
                      <Text fw={500} c="gray">
                        {data?.supplier?.partner?.phone ?? "(+168) 99******"}
                      </Text>
                    </Flex>
                    <Flex gap="sm" justify="start" align="start" w="100%">
                      <IconUserCircle color={theme.colors.violet[6]} />
                      <Text fw={500} c="gray">
                        Борлуулалт хариуцсан: {data?.supplier?.salesStaff?.user?.lastName && `${data?.supplier?.salesStaff?.user?.lastName[0]}.`}
                        {data?.supplier?.salesStaff?.user?.firstName}
                      </Text>
                    </Flex>
                    <Flex gap="sm" justify="start" align="start" w="100%">
                      <IconId color={theme.colors.violet[6]} />
                      <Text fw={500} c="gray">
                        Санхүү хариуцсан: {data?.supplier?.financeStaff?.user?.lastName && `${data?.supplier?.financeStaff?.user?.lastName[0]}.`}
                        {data?.supplier?.financeStaff?.user?.firstName}
                      </Text>
                    </Flex>
                  </Flex>
                  <Divider color="gray" styles={{ label: { fontSize: 16, fontWeight: 600 } }} w="100%" label="Категори жагсаалт" />
                  <Flex direction="column" gap="xs" justify="start" align="start" w="100%">
                    {data?.categories?.map((item: any) => {
                      return (
                        <Text fw={500} c="gray" key={item.id}>
                          {item.name ?? "-"}
                        </Text>
                      );
                    })}
                    {data?.categories?.length === 0 && (
                      <Anchor size={"md"} fw={500} c="gray">
                        <Flex align={"center"} gap={8}>
                          <IconBoxModelOff size={"24px"} /> Категори байхгүй.
                        </Flex>
                      </Anchor>
                    )}
                  </Flex>
                </Flex>
              </Box>
            </Grid.Col>
            <Grid.Col span={{ xs: 12, sm: 12, md: 12, lg: 8, xl: 9 }}>
              {data?.subCategories?.length > 0 && (
                <Flex mb={8} align="center" gap={8} wrap="wrap">
                  {data?.subCategories?.map((item: any, index: number) => {
                    return (
                      <Button c="dimmed" variant="default" key={index}>
                        {item?.name || "N/A"}
                      </Button>
                    );
                  })}
                </Flex>
              )}
              <Paper>
                {ERP_STORE_FETCH.hasAccess ? (
                  <ProductTable
                    ref={suppTableRef}
                    onClick={onClick}
                    name="our-supplier.list"
                    filters={{
                      supplierId: params.id,
                    }}
                    loadData={(data) => SuppApi.goods(data!)}
                  />
                ) : (
                  ERP_STORE_FETCH.accessDenied()
                )}
              </Paper>
            </Grid.Col>
          </Grid>
        </>
      ) : (
        ERP_STORE.accessDenied()
      )}

      <Modal
        size={"xl"}
        withCloseButton={false}
        centered
        opened={action[0] === "fetch"}
        title={false}
        onClose={() => {
          setAction([]);
        }}>
        <SuppFetchForm
          reload={async () => {
            await suppTableRef.current?.reload();
          }}
          action={action}
          onCancel={() => setAction([])}
          dataSource={action[1]}
        />
      </Modal>
    </PageLayout>
  );
};

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    to: "/supp",
    label: "Нийлүүлэгчийн бараа",
  },
  {
    label: "Дэлгэрэнгүй",
  },
];

const useStyle = createStyles(() => ({}));
