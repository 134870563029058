import { ActionIcon, Avatar, Badge, Box, Flex, Group, Text } from "@mantine/core";
import { IconPhoto, IconTrash } from "@tabler/icons-react";
import { useSelector } from "react-redux";
import { Tag } from "../../components/tags";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../models/General";
import { currencyFormat } from "../../utils";
import { NumberCurrencyField } from "../form/number-currency-field";
import { ColumnType, Table } from "../table";

export function PriceChangeInfoTable({ variants, setFieldValue, disabled = false }: { variants: any; setFieldValue: any; disabled?: boolean }) {
  const { tiers, currencies, variantPriceHistoryStatus } = useSelector((state: { general: IGeneral }) => state?.general);

  const columns = useHeader({
    variantPriceHistoryStatus,
    Tag,
    currencies,
    disabled,
    onClick: (key, record) => {
      switch (key) {
        case "remove":
          setFieldValue(
            "variants",
            variants.filter((item: any) => item.id !== record.id),
          );
          break;

        default:
          break;
      }
    },
    tiers,
  });

  return <Table placeholder="Та нэг ч бараа сонгоогүй байна." name="price.change.information" columns={columns} dataSource={variants || []} />;
}

const useHeader = ({
  onClick,
  tiers,
  currencies,
  variantPriceHistoryStatus,
  disabled,
  Tag,
}: {
  onClick: (key: string, record: any) => void;
  disabled: boolean;
  tiers: any;
  currencies: IReference[];
  variantPriceHistoryStatus: IReference[];
  Tag: any;
}): ColumnType<any>[] => [
  {
    isHide: disabled,
    title: "Үйлдэл",
    render: (record) => (
      <>
        <Flex align={"center"} gap={"xs"}>
          <ActionIcon disabled={disabled} onClick={() => onClick("remove", record)} variant="light" color="">
            <IconTrash size={"1.2rem"} />
          </ActionIcon>
        </Flex>
      </>
    ),
  },
  {
    title: "Зураг",
    sorter: true,
    dataIndex: "image",
    render: (record) => (
      <Avatar src={record.image} radius="xs">
        <IconPhoto />
      </Avatar>
    ),
  },
  {
    title: "Бараа, бүтээгдэхүүний нэр",
    sorter: true,
    dataIndex: "nameMon",
    render: (record) => (
      <Text size="sm" w="max-content">
        {record?.nameMon ?? "-"}
      </Text>
    ),
  },
  {
    title: "SKU код",
    sorter: true,
    dataIndex: "skuCode",
    render: (record) => (
      <Text size="sm" w="max-content">
        {record?.skuCode || "-"}
      </Text>
    ),
  },
  {
    title: "Хэмжих нэгж",
    sorter: true,
    dataIndex: "unitName",
    render: (record) => (
      <Text size="sm" w="max-content">
        {record?.unitName || "-"}
      </Text>
    ),
  },
  {
    title: "Үндсэн нэгж эсэх",
    sorter: true,
    dataIndex: "isBaseUnit",
    render: (record) => (
      <Badge variant="outline" color={record.isBaseUnit ? "green.5" : "gray.5"}>
        {record.isBaseUnit ? "Тийм" : "Үгүй"}
      </Badge>
    ),
  },
  {
    title: "Үндсэн нэгжээр тоо",
    sorter: true,
    dataIndex: "unitConvertValue",
    render: (record) => <Text>{record?.unitConvertValue || 0}</Text>,
  },
  {
    title: "Үнэ тооцох тоо",
    sorter: true,
    dataIndex: "unit",
    render: (record) => "-",
  },
  {
    title: "Валют",
    sorter: true,
    dataIndex: "currency",
    render: (record) => (
      <Text size="sm" w="max-content">
        {currencies.find((item: IReference) => item.code === record.currency)?.name || "-"}
      </Text>
    ),
  },
  {
    title: "Стандарт үнэ",
    sorter: true,
    render: (record, index) => {
      return (
        <Box>
          {!disabled ? (
            <NumberCurrencyField disabled={disabled} size="xs" name={`variants[${index}].standardPrice`} placeholder="0.00" />
          ) : (
            <Group justify={"right"}>
              <Text size="sm">{currencyFormat(record?.standardPrice || 0)}</Text>
            </Group>
          )}
        </Box>
      );
    },
  },
  {
    title: tiers.find((item: any) => item.tierNo === 1)?.name || "Tier 0",
    render: (record, index) => {
      return (
        <Box>
          {!disabled ? (
            <NumberCurrencyField disabled={disabled} size="xs" name={`variants[${index}].tier0Price`} placeholder="0.00" />
          ) : (
            <Group justify={"right"}>
              <Text size="sm">{currencyFormat(record?.tier0Price || 0)}</Text>
            </Group>
          )}
        </Box>
      );
    },
  },
  {
    title: "Өөрчлөлт",
    render: (record) => (
      <Group justify="right">
        <Text c={(record?.tier0Price || 0) - (record?.standardPrice || 0) < 0 ? "red" : "green"} size="sm">
          {currencyFormat((record?.tier0Price || 0) - (record?.standardPrice || 0))}
        </Text>
      </Group>
    ),
  },
];
