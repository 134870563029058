import { useState } from "react";
import { CheckIcon, Combobox, Group, Pill, PillsInput, useCombobox } from "@mantine/core";

type Props = {
  name: string;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  clearable?: boolean;
  styles?: Record<string, any>;
  noError?: boolean;
  value: string[];
  onChange: (value: string[] | null) => void;
  error?: React.ReactNode;
};

export function MultiSelectCreatable({ name, label, placeholder, disabled = false, noError = false, value, onChange, error }: Props) {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => combobox.updateSelectedOptionIndex("active"),
  });

  const [search, setSearch] = useState("");
  const [data, setData] = useState<string[]>([""]);
  const exactOptionMatch = data.some((item) => item === search);

  const handleValueSelect = (val: string) => {
    setSearch("");

    if (val === "$create") {
      setData((current) => [...current, search]);
      onChange([...value, search]);
    } else {
      const newValue = value.includes(val) ? value.filter((v) => v !== val) : [...value, val];
      onChange(newValue);
    }
  };

  const handleValueRemove = (val: string) => {
    const newValue = value.filter((v) => v !== val);
    onChange(newValue);
  };

  const values = value.map((item) => (
    <Pill key={item} withRemoveButton onRemove={() => handleValueRemove(item)}>
      {item}
    </Pill>
  ));

  const options = data
    .filter((item) => item.toLowerCase().includes(search.trim().toLowerCase()))
    .map((item) => (
      <Combobox.Option value={item} key={item} active={value.includes(item)}>
        <Group gap="sm">
          {value.includes(item) ? <CheckIcon size={12} /> : null}
          <span>{item}</span>
        </Group>
      </Combobox.Option>
    ));

  return (
    <div>
      {label && <label>{label}</label>}
      <Combobox store={combobox} onOptionSubmit={handleValueSelect} withinPortal={false}>
        <Combobox.DropdownTarget>
          <PillsInput onClick={() => combobox.openDropdown()} disabled={disabled}>
            <Pill.Group>
              {values}

              <Combobox.EventsTarget>
                <PillsInput.Field
                  onFocus={() => combobox.openDropdown()}
                  onBlur={() => combobox.closeDropdown()}
                  value={search}
                  placeholder={placeholder}
                  onChange={(event) => {
                    combobox.updateSelectedOptionIndex();
                    setSearch(event.currentTarget.value);
                  }}
                  onKeyDown={(event) => {
                    if (event.key === "Backspace" && search.length === 0) {
                      event.preventDefault();
                      handleValueRemove(value[value.length - 1]);
                    }
                  }}
                />
              </Combobox.EventsTarget>
            </Pill.Group>
          </PillsInput>
        </Combobox.DropdownTarget>

        <Combobox.Dropdown>
          <Combobox.Options>
            {options}

            {!exactOptionMatch && search.trim().length > 0 && <Combobox.Option value="$create">+ Create {search}</Combobox.Option>}

            {exactOptionMatch && search.trim().length > 0 && options.length === 0 && <Combobox.Empty>Nothing found</Combobox.Empty>}
          </Combobox.Options>
        </Combobox.Dropdown>
      </Combobox>

      {!noError && error && <div style={{ color: "red" }}>{error}</div>}
    </div>
  );
}
