import { Alert, Box, Button, Checkbox, Divider, Grid, Group, Input, LoadingOverlay, Paper, Space, Text } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import { IconAlertCircle, IconCalendar, IconPercentage } from "@tabler/icons-react";
import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import * as yup from "yup";
import { IAuth } from "../../interfaces/IAuth";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../models/General";
import { dateFormat } from "../../utils/date";
import { Form, IFormRef } from "../form";
import { DatePickerField } from "../form/datepicker-field";
import { NumberCurrencyField } from "../form/number-currency-field";
import { NumberField } from "../form/number-field";
import { SelectField } from "../form/select-field";
import { TextField } from "../form/text-field";
import { FormLayout } from "../layout";
import { Tag } from "../tags";
import { PriceChangeInfoTable } from "./price-change-info-table";
import PriceSpecialSelectList from "./price-special-select-list";
import dayjs from "dayjs";

const schema = yup.object({
  type: yup.string().required("Заавал бөглөнө!").nullable().trim(),
  isAuto: yup.boolean(),
  name: yup.string().required("Заавал бөглөнө!").nullable().trim(),
  perType: yup
    .string()
    .when("isAuto", {
      is: true,
      then: (schema) => schema.required("Заавал бөглөнө!").nullable().trim(),
    })
    .optional(),
  perValueAmount: yup
    .number()
    .when("isAuto", {
      is: true,
      then: (schema) =>
        schema
          .when("perType", {
            is: "AMOUNT",
            then: (schema) => schema.min(0, "0-с бага байна!").typeError("Заавал бөглөнө!").required("Заавал бөглөнө!").nullable(),
          })
          .optional(),
    })
    .nullable()
    .optional(),
  perValuePercentage: yup
    .number()
    .when("isAuto", {
      is: true,
      then: (schema) =>
        schema
          .when("perType", {
            is: "PERCENTAGE",
            then: (schema) => schema.min(0, "0-с бага байна!").max(100, "100-с хэтрэхгүй!").typeError("Заавал бөглөнө!").required("Заавал бөглөнө!").nullable(),
          })
          .nullable()
          .optional(),
    })
    .nullable()
    .optional(),
  changeType: yup
    .string()
    .when("isAuto", {
      is: true,
      then: (schema) => schema.required("Заавал бөглөнө!").nullable().trim(),
    })
    .optional(),
  description: yup.string().required("Заавал бөглөнө!").nullable().trim(),
  startDate: yup.string().required("Заавал бөглөнө!").nullable().trim(),
  endDate: yup.string().required("Заавал бөглөнө!").nullable().trim(),
  variants: yup
    .array()
    .of(
      yup.object({
        variantId: yup.string().required("Заавал бөглөнө!").nullable().trim(),
        tier0Price: yup.number().typeError("Заавал бөглөнө!").required("Заавал бөглөнө!").nullable(),
      }),
    )
    .required("Заавал бөглөнө!"),
  confirm: yup.boolean(),
});

export type ITypeSpecialPrice = {
  type: "SPECIAL";
  name: string | undefined;
  perType: string | undefined;
  perValue: number | undefined;
  changeType: string | undefined;
  description: string | undefined;
  startDate: string | undefined;
  endDate: string | undefined;
  variants: {
    variantId: string | undefined;
    tier0Price: number | undefined;
  }[];
  confirm: boolean;
  isAuto: boolean;
  perValueAmount: number | undefined;
  perValuePercentage: number | undefined;
};

type MainFormProps = {
  editable: any[];
  onSubmit: (values: ITypeSpecialPrice) => void;
  loading: boolean;
};

export function SpecialPriceForm({ onSubmit, editable, loading }: MainFormProps) {
  const navigate = useNavigate();
  const { priceChangeTypes, perTypes, priceGroupStatus } = useSelector((state: { general: IGeneral }) => state?.general);
  const { user } = useSelector((state: { auth: IAuth }) => state.auth);

  const { theme } = useStyle();
  const IFormRef = useRef<IFormRef<any>>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [colors, setColors] = useState<string[]>(["gray", "green", "red", "black"]);

  const [data] = React.useState<ITypeSpecialPrice>({
    perType: undefined,
    perValue: undefined,
    changeType: undefined,
    description: undefined,
    startDate: editable[0] === "edit" ? editable[1].startDate : dayjs(new Date()).format(),
    endDate: editable[0] === "edit" ? editable[1].endDate : dayjs(new Date()).format(),
    confirm: false,
    isAuto: ((editable[0] === "edit" || editable[0] === "details") && !!editable[1]?.perType) || false,
    name: undefined,
    perValueAmount: ((editable[0] === "edit" || editable[0] === "details") && editable[1]?.perValue) || null,
    perValuePercentage: ((editable[0] === "edit" || editable[0] === "details") && editable[1]?.perValue) || undefined,
    ...((editable[0] === "edit" || editable[0] === "details") && editable[1]),
    type: "SPECIAL",
    variants:
      editable[0] === "edit" || editable[0] === "details"
        ? editable[1]?.variants?.map((item: any) => {
            return {
              ...item,
              id: item?.id || item?.variantId,
              tier0Price: item?.tier0Price || item?.price || undefined,
              tier1Price: item?.tier1Price || item?.price || undefined,
            };
          }) || []
        : [],
  });

  const now = Date.now();

  return (
    <Paper>
      <Form ref={IFormRef} validationSchema={schema} onSubmit={() => onSubmit} initialValues={data}>
        {({ values, setFieldValue, errors }) => {
          return (
            <FormLayout
              my={20}
              title="Үнийн жагсаалтын мэдээлэл"
              subTitle=""
              extra={
                editable[0] !== "details"
                  ? [
                      <Button
                        loading={loading}
                        onClick={() => {
                          navigate("/policy");
                        }}
                        key={1}
                        variant="default">
                        Болих
                      </Button>,
                      <Button
                        loading={loading}
                        onClick={async () => {
                          const values = await IFormRef.current?.submit();
                          if (values) {
                            onSubmit({ ...values, confirm: false });
                          }
                        }}
                        variant="outline"
                        key={2}>
                        Хадгалах
                      </Button>,
                      <Button
                        loading={loading}
                        disabled={editable[0] === "details"}
                        onClick={async () => {
                          const values = await IFormRef.current?.submit();
                          if (values) {
                            onSubmit({ ...values, confirm: true });
                          }
                        }}
                        key={3}>
                        Батлах
                      </Button>,
                    ]
                  : []
              }>
              <LoadingOverlay visible={loading} />
              <Divider mb="lg" />
              <Grid>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <Input.Wrapper label="Үнийн код" required>
                    <Input value={(editable[1] && editable[1]?.refCode) || "Авто үүснэ."} disabled />
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <Input.Wrapper label="Статус" required>
                    <Box style={{ alignItems: "center" }}>
                      {Tag(
                        [
                          ...priceGroupStatus?.map((item1: any, index: number) => {
                            return {
                              ...item1,
                              color: priceGroupStatus?.length === colors.length ? colors[index] : "grape",
                            };
                          }),
                        ].find((item: any) => item.code === values?.priceGroupStatus)?.color || "red",
                        priceGroupStatus?.find((item: any) => item.code === values?.priceGroupStatus)?.name || "Идэвхгүй",
                      )}
                    </Box>
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <Input.Wrapper label="Бүртгэсэн" required>
                    <Input
                      value={`${user?.lastName ? user?.lastName[0] + "." : ""} ${user?.firstName || "-"}, ${editable[1] && dateFormat(editable[1].createdAt)}`}
                      disabled
                    />
                  </Input.Wrapper>
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <TextField disabled={editable[0] === "details"} name="name" placeholder="Нэр оруулах" label="Үнийн жагсаалтын нэр" required />
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <TextField disabled={editable[0] === "details"} name="description" placeholder="Тайлбар оруулах" label="Тайлбар" required />
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <Input.Wrapper label="Өөрчлөлт хийх арга" required>
                    <Group mt={"8px"}>
                      <Checkbox
                        disabled={editable[0] === "details"}
                        onChange={() => {
                          setFieldValue("isAuto", false);
                          setFieldValue("perType", undefined);
                          setFieldValue("changeType", undefined);
                          setFieldValue("perValue", undefined);
                          setFieldValue("perValuePercentage", undefined);
                          setFieldValue("perValueAmount", undefined);
                          setFieldValue(
                            "variants",
                            values?.variants?.map((item: any) => {
                              return {
                                ...item,
                                tier0Price: undefined,
                              };
                            }),
                          );
                        }}
                        checked={!values.isAuto}
                        label="Гараас оруулна"
                      />
                      <Checkbox
                        disabled={editable[0] === "details"}
                        onChange={() => {
                          setFieldValue("isAuto", true);
                          setFieldValue(
                            "variants",
                            values?.variants?.map((item: any) => {
                              return {
                                ...item,
                                tier0Price: undefined,
                              };
                            }),
                          );
                        }}
                        checked={values.isAuto}
                        label="Автоматаар бодуулна"
                      />
                    </Group>
                  </Input.Wrapper>
                </Grid.Col>

                {values.isAuto && (
                  <>
                    <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                      <SelectField
                        disabled={editable[0] === "details"}
                        name="perType"
                        label="Өөрчлөлт төрөл:"
                        placeholder="Сонгох"
                        options={
                          perTypes.map((item: IReference) => {
                            return { value: item.code, label: item.name };
                          }) || []
                        }
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                      {values.perType === "AMOUNT" ? (
                        <NumberCurrencyField
                          disabled={editable[0] === "details" || !values?.perType}
                          name="perValueAmount"
                          label="Өөрчлөлт дүн:"
                          placeholder={"Үнийн дүн оруулах"}
                        />
                      ) : (
                        <NumberField
                          disabled={editable[0] === "details" || !values?.perType}
                          name="perValuePercentage"
                          label="Өөрчлөлт дүн:"
                          placeholder={"Хувь оруулах"}
                          rightSection={<IconPercentage size={"1.2rem"} color={theme.colors.gray[4]} />}
                          rightSectionWidth={32}
                        />
                      )}
                    </Grid.Col>
                    <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                      <SelectField
                        disabled={editable[0] === "details"}
                        name="changeType"
                        label="Өөрчлөлт хийх арга:"
                        placeholder="Сонгох"
                        options={
                          priceChangeTypes.map((item: IReference) => {
                            return { value: item.code, label: item.name };
                          }) || []
                        }
                      />
                    </Grid.Col>
                  </>
                )}
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <DatePickerField
                    disabled={editable[0] === "details"}
                    minDate={new Date(now)}
                    name="startDate"
                    label="Үнэ эхлэх огноо:"
                    placeholder="Огноо сонгох"
                    showTime
                    icon={<IconCalendar size={"1.2rem"} color={theme.colors.gray[4]} />}
                    onChange={() => {
                      setFieldValue("endDate", null);
                    }}
                  />
                </Grid.Col>
                <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
                  <DatePickerField
                    disabled={editable[0] === "details" || !values?.startDate}
                    minDate={new Date(values?.startDate) || new Date(now)}
                    name="endDate"
                    label="Үнэ дуусах огноо:"
                    placeholder="Огноо сонгох"
                    showTime
                    icon={<IconCalendar size={"1.2rem"} color={theme.colors.gray[4]} />}
                  />
                </Grid.Col>
                <Grid.Col>
                  <Text fw={600} fz={"lg"}>
                    Үнэ өөрчлөх бараагаа сонгоно уу:​
                  </Text>

                  <Space h={"xs"} />

                  <Box>
                    <PriceSpecialSelectList disabled={editable[0] === "details"} setFieldValue={setFieldValue} values={values} />
                  </Box>

                  <Space h={"md"} />

                  <PriceChangeInfoTable disabled={editable[0] === "details"} setFieldValue={setFieldValue} variants={values?.variants || []} />

                  {errors.variants && (
                    <Alert icon={<IconAlertCircle />} mt={"xs"} color="red">
                      {errors.variants}
                    </Alert>
                  )}
                </Grid.Col>
              </Grid>
            </FormLayout>
          );
        }}
      </Form>
    </Paper>
  );
}

const useStyle = createStyles({});
