import { ActionIcon, Box, Button, Checkbox, Collapse, Flex, Grid, Group, Input, LoadingOverlay, Modal, Paper, Space, Text, Tooltip } from "@mantine/core";
import { createStyles } from "@mantine/emotion";
import { IconPlus } from "@tabler/icons-react";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import useSwr from "swr";
import * as yup from "yup";
import { GoodsApi } from "../../apis";
import { IGeneral } from "../../interfaces/IGeneral";
import { message } from "../../utils/message";
import { Form, IFormRef } from "../form";
import { CheckboxField } from "../form/checkbox-field";
import { MultiCheckboxField } from "../form/multi-checkbox-field";
import { NumberField } from "../form/number-field";
import { NumberFieldMany } from "../form/number-field-many";
import { SelectField } from "../form/select-field";
import { usePermission } from "../permission";
import { UnitForm } from "../reference/unit-form";
import { NoVariantAddUnit } from "./no-variant-add-unit";
import { VariantAddUnit } from "./variant-add-unit";
import { VariantsInfo } from "./variants";

const schema = yup.object().shape({
  hasVariant: yup.boolean(),
  supplierType: yup.string().required("Заавал бөглөнө!").nullable(),
  baseUnitId: yup.string().required("Заавал бөглөнө!").nullable(),
  weight: yup.number().typeError("Тоон утга оруулна уу!").min(0, "0-с их байх!").required("Заавал бөглөнө!").nullable().optional(),
  weightLabel: yup.string().required("Заавал бөглөнө!").nullable().optional(),
  length: yup.string().required("Заавал бөглөнө!").nullable().optional(),
  height: yup.string().required("Заавал бөглөнө!").nullable().optional(),
  width: yup.string().required("Заавал бөглөнө!").nullable().optional(),
  spaceLabel: yup.string().required("Заавал бөглөнө!").nullable().optional(),
  returnAllow: yup.boolean().optional(),
  returnType: yup
    .string()
    .when("returnAllow", {
      is: true,
      then: (schema) => schema.required("Заавал бөглөнө!").nullable(),
    })
    .optional(),
  additionalUnits: yup
    .array()
    .when("hasVariant", {
      is: false,
      then: (schema) =>
        schema
          .when("hasAdditionalUnit", {
            is: true,
            then: (schema) =>
              schema
                .of(
                  yup.object().shape({
                    isForLoad: yup.boolean(),
                    unitId: yup.string().required("Заавал бөглөнө!"),
                    convertType: yup.string().required("Заавал бөглөнө!"),
                    convertValue: yup.number().typeError("Заавал бөглөнө!").required("Заавал бөглөнө!"),
                    weightLabel: yup.string().optional(),
                    floatValue: yup.number().optional(),
                    spaceLabel: yup.string().optional(),
                    height: yup.number().optional(),
                    width: yup.number().optional(),
                    length: yup.number().optional(),
                    weight: yup.number().optional(),
                  }),
                )
                .min(1, "Заавал бөглөнө!")
                .required("empty"),
          })
          .optional(),
    })
    .nullable()
    .optional(),
});

type IFormType = {
  supplierType: null | string;
  baseUnitId: null | string;
  weight: null | number;
  weightLabel: null | string;
  length: null | number;
  height: null | number;
  width: null | number;
  spaceLabel: null | string;
  returnAllow: boolean;
  returnType: null | string;
  hasVariant: boolean;
  hasAdditionalUnit: boolean;
  additionalUnits: {
    unitId: null | string;
    convertType: null | string;
    convertValue: null | number;
    floatValue: null | number;
    isForLoad: boolean;
    spaceLabel: null | string;
    height: null | number;
    width: null | number;
    length: null | number;
    weightLabel: null | string;
    weight: null | number;
  }[];
  isCompleted: boolean;
};

function ProductOrderSettingsTab() {
  const ERP_GDS = usePermission("ERP_GDS");
  const { units, unitSpaceLabels, unitWeightLabels, supplierTypes } = useSelector((state: { general: IGeneral }) => state.general);
  const params = useParams();
  const navigate = useNavigate();
  const { data: dataUpdate, mutate, isLoading } = useSwr<any>(`/api/goods/${JSON.stringify(params.id!)}`, async () => await GoodsApi.get(`${params.id!}`));
  const { classes } = useStyle();
  const [action, setAction] = useState<any[]>([]);
  const formRef = useRef<IFormRef<any>>(null);
  const [hasAdditionalUnit, setHasAdditionalUnit] = useState<boolean>(false);
  const [hasVariant, setHasVariant] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const reload = async () => {
    await mutate();
  };

  const onSubmit = async (isCompleted: boolean) => {
    let data: IFormType = await formRef.current?.submit();

    if (data) {
      setLoading(true);
      try {
        if (hasVariant) {
          await GoodsApi.updateVariant(params.id!, {
            supplierType: data.supplierType,
            baseUnitId: data.baseUnitId,
            weight: data.weight,
            weightLabel: data.weightLabel,
            length: data.length,
            height: data.height,
            width: data.width,
            spaceLabel: data.spaceLabel,
            returnAllow: data.returnAllow,
            returnType: data.returnType,
            hasVariant: true,
            hasAdditionalUnit: hasAdditionalUnit,
            isCompleted: isCompleted,
          });
        } else {
          await GoodsApi.updateVariant(params.id!, {
            supplierType: data.supplierType,
            baseUnitId: data.baseUnitId,
            weight: data.weight,
            weightLabel: data.weightLabel,
            length: data.length,
            height: data.height,
            width: data.width,
            spaceLabel: data.spaceLabel,
            returnAllow: data.returnAllow,
            returnType: data.returnType,
            hasVariant: false,
            hasAdditionalUnit: data.hasAdditionalUnit,
            additionalUnits:
              data?.additionalUnits?.map((item: any) => {
                return {
                  unitId: item?.unitId,
                  convertType: item?.convertType,
                  convertValue: item?.convertValue,
                  floatValue: item?.floatValue,
                  isForLoad: item?.isForLoad,
                  spaceLabel: item?.spaceLabel,
                  height: item?.height,
                  width: item?.width,
                  length: item?.length,
                  weightLabel: item?.weightLabel,
                  weight: item?.weight,
                };
              }) || [],
            isCompleted: isCompleted,
          });
        }
        message.success("Хүсэлт амжилттай.");

        if (isCompleted) {
          navigate("/product");
        }
      } catch (error: any) {
        message.error(error.message || "Хүсэлт амжилтгүй!");
      }
    } else {
    }

    await reload();
    setLoading(false);
  };

  useEffect(() => {
    setHasVariant(dataUpdate?.hasVariant || false);
    setHasAdditionalUnit(dataUpdate?.hasAdditionalUnit || dataUpdate?.additionalUnits?.length > 0 || false);
  }, [dataUpdate]);

  console.log("units::::>", units);

  if (isLoading) {
    return <LoadingOverlay visible />;
  } else
    return (
      <Paper my={"lg"}>
        <Box maw="100%">
          <Form
            ref={formRef}
            onSubmit={(data) => data}
            initialValues={{
              supplierType: dataUpdate?.supplierType ? dataUpdate?.supplierType : "PRODUCER",
              baseUnitId: dataUpdate?.itemUnits.find((i: any) => i.isBase)?.unitId || units[0].id,
              weight: dataUpdate?.itemUnits.find((i: any) => i.isBase)?.weight || null,
              weightLabel: dataUpdate?.itemUnits.find((i: any) => i.isBase)?.weightLabel || null,
              length: dataUpdate?.itemUnits.find((i: any) => i.isBase)?.length || null,
              height: dataUpdate?.itemUnits.find((i: any) => i.isBase)?.height || null,
              width: dataUpdate?.itemUnits.find((i: any) => i.isBase)?.width || null,
              spaceLabel: dataUpdate?.itemUnits.find((i: any) => i.isBase)?.spaceLabel || null,
              returnAllow: dataUpdate?.returnAllow || false,
              returnType: dataUpdate?.returnType,

              hasVariant: dataUpdate?.hasVariant || false,
              hasAdditionalUnit: dataUpdate?.hasAdditionalUnit || false,
              additionalUnits: dataUpdate?.additionalUnits || [],
              isCompleted: dataUpdate?.isCompleted || false,
            }}
            validationSchema={schema}>
            {({ values, errors, setFieldValue }) => {
              return (
                <div>
                  <Grid>
                    <Grid.Col>
                      <Group align="center" justify="space-between">
                        <Text fw={600} fz={"lg"}>
                          Барааны ханган нийлүүлэгч
                        </Text>
                        <Group>
                          <Button onClick={() => navigate(-1)} variant="default">
                            Өмнөх
                          </Button>
                          <Button
                            hidden={!ERP_GDS.isEdit}
                            loading={loading}
                            onClick={() => {
                              onSubmit(false);
                            }}
                            variant="outline">
                            Хадгалах
                          </Button>
                          <Button
                            hidden={!ERP_GDS.isCreate}
                            loading={loading}
                            onClick={() => {
                              onSubmit(true);
                            }}>
                            Бүртгэл дуусгах
                          </Button>
                        </Group>
                      </Group>

                      <MultiCheckboxField
                        disabled={dataUpdate?.isFetched}
                        label=""
                        name="supplierType"
                        data={
                          supplierTypes?.map((item: any) => {
                            return {
                              label: item.name,
                              value: item.code,
                            };
                          }) || []
                        }
                      />
                    </Grid.Col>
                    <Grid.Col>
                      <Text fw={600} fz={"lg"} mt={"sm"}>
                        Захиалгын нэгж, хэмжээсний мэдээлэл
                      </Text>
                    </Grid.Col>
                    <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                      <Flex align={"end"} gap={8}>
                        <SelectField
                          rightSection={
                            <Tooltip label="Нэгж нэмэх">
                              <ActionIcon onClick={() => setAction(["unit_new"])} variant="light" color="violet">
                                <IconPlus size={18} />
                              </ActionIcon>
                            </Tooltip>
                          }
                          onChange={(e) => {
                            console.log(e);
                          }}
                          name="baseUnitId"
                          label="Үндсэн хэмжих нэгж"
                          placeholder="Үндсэн хэмжих нэгж"
                          options={units?.map((item) => ({ label: item.name, value: item.id })) || []}
                          required
                        />
                      </Flex>
                    </Grid.Col>
                    <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                      <NumberField
                        name="weight"
                        label="Үндсэн нэгжийн жин"
                        placeholder="00.00|"
                        rightSectionWidth={130}
                        rightSection={
                          <SelectField
                            noError
                            name="weightLabel"
                            clearable={false}
                            options={
                              unitWeightLabels.map((item) => {
                                return {
                                  label: item.name,
                                  value: item.code,
                                };
                              }) || []
                            }
                            placeholder="Жин сонгох"
                          />
                        }
                      />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                      <NumberFieldMany
                        names={["length", "height", "width"]}
                        placeholders={["Урт", "Өндөр", "Өргөн"]}
                        rightSection={
                          <SelectField
                            noError
                            name="spaceLabel"
                            clearable={false}
                            classNames={{
                              input: classes.selectRightSection,
                            }}
                            options={
                              unitSpaceLabels?.map((item) => {
                                return {
                                  label: item.name,
                                  value: item.code,
                                };
                              }) || []
                            }
                            placeholder="CM"
                          />
                        }
                      />
                    </Grid.Col>
                    {/* <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                      <CheckboxField name="returnAllow" label="Буцаалт зөвшөөрөх эсэх" />
                    </Grid.Col>
                    <Grid.Col span={{ xs: 12, sm: 6, md: 4, lg: 3 }}>
                      <SelectField
                        disabled={!values.returnAllow}
                        name="returnType"
                        label="Буцаалтын төрөл"
                        placeholder="Буцаалтын төрөл"
                        options={
                          returnTypes?.map((item: any) => {
                            return {
                              label: item.name,
                              value: item.code,
                            };
                          }) || []
                        }
                      />
                    </Grid.Col> */}
                    <Grid.Col>
                      <Text fz={"lg"} fw={600} mb={"lg"}>
                        Хувилбарын мэдээлэл
                      </Text>
                      <Group>
                        <Checkbox
                          onChange={() => {
                            setFieldValue("additionalUnits", []);
                            setFieldValue("hasAdditionalUnit", false);
                            setFieldValue("hasVariant", true);
                            setHasAdditionalUnit(false);
                            setHasVariant(true);
                          }}
                          checked={values?.hasVariant}
                          label="Хувилбартай"
                          disabled={dataUpdate.variantSelected}
                        />
                        <Checkbox
                          onChange={() => {
                            setFieldValue("additionalUnits", []);
                            setFieldValue("hasAdditionalUnit", false);
                            setFieldValue("hasVariant", false);
                            setHasAdditionalUnit(false);
                            setHasVariant(false);
                          }}
                          checked={!values.hasVariant}
                          label="Хувилбаргүй"
                          disabled={dataUpdate.variantSelected}
                        />
                      </Group>
                    </Grid.Col>
                    {!hasVariant && (
                      <Grid.Col>
                        <CheckboxField
                          error={!values?.baseUnitId && "Үндсэн хэмжих нэгж ээ сонгоно уу."}
                          disabled={!values?.baseUnitId}
                          onChangeCustom={(e) => setHasAdditionalUnit(e)}
                          name="hasAdditionalUnit"
                          label="Нэмэлт хэмжих нэгжтэй юу?"
                        />
                        <Space h="xs" />
                        <Collapse in={values?.hasAdditionalUnit}>
                          <Text fw={600} fz={"lg"} mb={"md"}>
                            Хэмжих нэгж болон хэмжээс
                          </Text>
                          <Paper>
                            <NoVariantAddUnit
                              values={{ ...values, baseUnitId: dataUpdate?.itemUnits.find((i: any) => i.isBase)?.unitId || null }}
                              setFieldValue={setFieldValue}
                              error={errors.additionalUnits === "empty"}
                            />
                          </Paper>
                        </Collapse>
                      </Grid.Col>
                    )}
                  </Grid>
                </div>
              );
            }}
          </Form>

          <Collapse in={hasVariant}>{<VariantsInfo dataUpdate={dataUpdate} reload={reload} />}</Collapse>

          {hasVariant && (
            <>
              <Space h="md" />
              <Input.Wrapper label="Нэмэлт хэмжих нэгжтэй юу?">
                <Space h="xs" />
                <Checkbox
                  error={!dataUpdate?.itemUnits.find((i: any) => i.isBase)?.unitId && "Үндсэн хэмжих нэгж ээ хадгална уу."}
                  disabled={!dataUpdate?.itemUnits.find((i: any) => i.isBase)?.unitId}
                  label="Тийм"
                  onChange={() => {
                    setHasAdditionalUnit(!hasAdditionalUnit);
                  }}
                  checked={hasAdditionalUnit}
                />
              </Input.Wrapper>
              <Collapse in={hasAdditionalUnit}>
                <VariantAddUnit
                  reload={async () => {
                    await mutate();
                  }}
                  dataUpdate={{ ...dataUpdate, hasAdditionalUnit: true, baseUnitId: dataUpdate?.itemUnits.find((i: any) => i.isBase)?.unitId || null }}
                />
              </Collapse>
            </>
          )}
        </Box>

        <Modal opened={action[0] === "unit_new"} onClose={() => setAction([])} withCloseButton={false} centered>
          <UnitForm
            title="Нэгж"
            subTitle={action[0] === "unit_new" ? "Нэгж нэмэх" : "Нэгж засах"}
            onCancel={() => setAction([])}
            action={action}
            payload={action[1] && action[1]}
            reloadTable={() => {}}
          />
        </Modal>
      </Paper>
    );
}

const useStyle = createStyles((theme) => ({
  tableContainer: {
    border: "1px solid #ced4da",
    width: "max-content",
  },

  selectRightSection: {
    border: "none",
  },
  selectRightSectionW: {
    width: 120,
  },
}));

export default ProductOrderSettingsTab;
