import { ActionIcon, Avatar, Badge, Box, CloseButton, Drawer, Flex, Grid, Image, Modal, Space, Text, Tooltip, UnstyledButton } from "@mantine/core";
import { useDebouncedState } from "@mantine/hooks";
import { IconBuildingWarehouse, IconCheck, IconEdit, IconFileText, IconFilter, IconPhoto, IconTags, IconTagsOff } from "@tabler/icons-react";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useSwr from "swr";
import { BrandApi, CategoryApi, DistributorApi, GoodsApi, GoodsWarehouseApi, ItemTypeApi, ManufacturerApi, VariantApi } from "../../apis";
import { FormLayout } from "../../components/layout";
import { ProductSetPriceForm } from "../../components/product-set-price";
import { ProductActiveForm } from "../../components/product-status/product-active-form";
import { ColumnType, ITableRef, Table } from "../../components/table";
import { WherehouseSetForm } from "../../components/wherehouse-set";
import { IGeneral } from "../../interfaces/IGeneral";
import { IItem } from "../../interfaces/IItem";
import { IReference } from "../../models/General";
import { updateManagers } from "../../store/general";
import { usePermission } from "../permission";
import { Segmented } from "../segmented";
import { SelectFilter } from "../select-filter";

const filterInit = {
  itemStatus: "",
  supplierType: "",
  returnAllow: "",
  warehouseId: "",
  brandId: "",
  manufacturerId: "",
  distributorId: "",
  itemTypeId: "",
  classificationId: "",
  subClassificationId: "",
  categoryId: "",
  subCategoryId: "",
};

export const ProductList = () => {
  const ERP_GDS_PRICE_SET = usePermission("ERP_GDS_PRICE_SET");
  const ERP_GDS_STATUS = usePermission("ERP_GDS_STATUS");

  const ERP_GDS_WRH_SET = usePermission("ERP_GDS_WRH_SET");
  const ERP_GDS = usePermission("ERP_GDS");

  const { supplierTypes = [], itemTypes = [], itemStatus = [] } = useSelector((state: { general: IGeneral }) => state.general);
  const [action, setAction] = useState<any[]>([]);
  const refTable = useRef<ITableRef>(null);
  const [filters, setFilters] = useState<any>(filterInit);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [warehouseFilterDelay, setWarehouseFilterDelay] = useDebouncedState<any>({ query: "" }, 800);

  const { data: warehouseSelects = [] } = useSwr<any[]>(
    ERP_GDS_WRH_SET.hasAccess && `/erp/goods/warehouse/select?query=${JSON.stringify(warehouseFilterDelay)}`,
    async () =>
      await GoodsWarehouseApi.select({
        query: warehouseFilterDelay.query || "",
        limit: 10,
      }),
    {
      fallback: [],
    },
  );

  const [itemTypeFilterDelay, setItemTypeSelectsFilterDelay] = useDebouncedState<any>({ query: "", isGoods: true, isService: false }, 800);
  const { data: itemTypeSelects = [] } = useSwr<any[]>(
    ERP_GDS.isEdit && `/erp/item_type/select?query=${JSON.stringify(itemTypeFilterDelay)}`,
    async () =>
      ERP_GDS.isEdit &&
      (await ItemTypeApi.select({
        ...itemTypeFilterDelay,
        limit: 10,
      })),
    {
      fallback: [],
    },
  );

  const [classificationQueryDelay, setClassificationQueryDelay] = useDebouncedState<string>("", 800);
  const [classificationFilter, setClassificationFilter] = useState<any>({ query: "", type: "CLASSIFICATION", itemTypeId: null, parentId: "" });

  const { data: classificationSelects = [] } = useSwr<any[]>(
    ERP_GDS.isEdit && `/erp/category/select?query=${JSON.stringify({ ...classificationFilter, query: classificationQueryDelay })}`,
    async () =>
      ERP_GDS.isEdit && classificationFilter.itemTypeId
        ? await CategoryApi.select({
            ...classificationFilter,
            query: classificationQueryDelay,
            limit: 10,
          })
        : null,
    {
      fallback: [],
    },
  );

  const [subClassificationFilter, setSubClassificationFilter] = useState<any>({
    query: "",
    type: "SUB_CLASSIFICATION",
    itemTypeId: null,
    parentId: null,
  });
  const [subClassificationQueyDelay, setSubClassificationQueryDelay] = useDebouncedState<string>("", 800);
  const { data: subClassificationSelects = [] } = useSwr<any[]>(
    ERP_GDS.isEdit && `/erp/category/select?query=${JSON.stringify({ ...subClassificationFilter, query: subClassificationQueyDelay })}`,
    async () =>
      ERP_GDS.isEdit && subClassificationFilter.itemTypeId && subClassificationFilter.parentId
        ? await CategoryApi.select({
            ...subClassificationFilter,
            query: subClassificationQueyDelay,
            limit: 10,
          })
        : null,
    {
      fallback: [],
    },
  );

  const [categoriesQueryDelay, setCategoriesQueryDelay] = useDebouncedState<string>("", 800);
  const [categoriesFilter, setCategoriesFilter] = useState<any>({ query: "", type: "CATEGORY", itemTypeId: null, parentId: "" });
  const { data: categoriesSelects = [] } = useSwr<any[]>(
    ERP_GDS.isEdit && `/erp/category/select?query=${JSON.stringify({ ...categoriesFilter, query: categoriesQueryDelay })}`,
    async () =>
      ERP_GDS.isEdit && categoriesFilter.itemTypeId
        ? await CategoryApi.select({
            ...categoriesFilter,
            query: categoriesQueryDelay,
            limit: 10,
          })
        : null,
    {
      fallback: [],
    },
  );

  const [subCategoriesQueryDelay, setSubCategoriesQueryDelay] = useDebouncedState<string>("", 800);
  const [subCategoriesFilter, setSubCategoriesFilter] = useState<any>({ query: "", type: "SUB_CATEGORY", itemTypeId: null, parentId: "" });
  const { data: subCategoriesSelects = [] } = useSwr<any[]>(
    ERP_GDS.isEdit && `/erp/category/select?query=${JSON.stringify({ ...subCategoriesFilter, query: subCategoriesQueryDelay })}`,
    async () =>
      ERP_GDS.isEdit && categoriesFilter.itemTypeId
        ? await CategoryApi.select({
            ...subCategoriesFilter,
            query: subCategoriesQueryDelay,
            limit: 10,
          })
        : null,
    {
      fallback: [],
    },
  );

  const [brandQueryDelay, setBrandQueryDelay] = useDebouncedState<string>("", 800);
  const { data: brandSelects = [] } = useSwr<any[]>(
    ERP_GDS.isEdit && `/erp/brand/select?query=${JSON.stringify(brandQueryDelay)}`,
    async () =>
      ERP_GDS.isEdit &&
      (await BrandApi.select({
        query: brandQueryDelay,
        limit: 10,
      })),
    {
      fallback: [],
    },
  );

  const [manufacturerQueryDelay, setManufacturerQueryDelay] = useDebouncedState<string>("", 800);
  const { data: manufacturerSelects = [] } = useSwr<any[]>(
    ERP_GDS.isEdit && `/erp/manufacturer/select?query=${JSON.stringify(manufacturerQueryDelay)}`,
    async () =>
      ERP_GDS.isEdit &&
      (await ManufacturerApi.select({
        query: manufacturerQueryDelay,
        limit: 10,
      })),
    {
      fallback: [],
    },
  );

  const [distributorQueryDelay, setDistributorQueryDelay] = useDebouncedState<string>("", 800);
  const { data: distributorSelects = [] } = useSwr<any[]>(
    ERP_GDS.isEdit && `/erp/distributor/select?query=${JSON.stringify(distributorQueryDelay)}`,
    async () =>
      ERP_GDS.isEdit &&
      (await DistributorApi.select({
        query: distributorQueryDelay,
        limit: 10,
      })),
    {
      fallback: [],
    },
  );

  const onClearFilter = () => {
    setFilters(filterInit);
    setClassificationFilter({ query: null, type: "CLASSIFICATION", itemTypeId: null, parentId: null });
    setSubClassificationFilter({
      query: null,
      type: "SUB_CLASSIFICATION",
      itemTypeId: null,
      parentId: null,
    });
    setCategoriesFilter({ query: null, type: "CATEGORY", itemTypeId: null, parentId: null });
    setSubCategoriesFilter({ query: null, type: "SUB_CATEGORY", itemTypeId: null, parentId: null });
  };

  const columns = useHeader({
    ERP_GDS_PRICE_SET,
    ERP_GDS_WRH_SET,
    ERP_GDS_STATUS,
    ERP_GDS,
    itemStatus,
    itemTypes,
    supplierTypes,
    onClick: async (key, record) => {
      switch (key) {
        case "image":
          setAction(["image", record]);
          break;
        case "edit":
          navigate(`/product/edit/${record.id}/1`);
          break;
        case "set_price":
          try {
            let resVariant = await VariantApi.get(record.variantId);

            setAction([key, { ...resVariant, variantId: record.variantId }]);
          } catch (error) {
            console.log(error);
          }

          break;
        case "set_wherehouse":
          let resVariant = await VariantApi.get(record.variantId);
          setAction([key, { ...resVariant, variantId: record.variantId }]);
          break;
        case "product_active":
          setAction([key, record]);
          break;
        case "product_inactive":
          setAction([key, record]);
          break;
        default:
          break;
      }
    },
  });

  return (
    <div>
      <div>
        <Flex pb="md" gap={20} style={{ overflow: "auto" }}>
          <Segmented
            label="Барааны статус"
            value={filters?.itemStatus || ""}
            onChange={(e: any) => {
              setFilters({
                ...filters,
                itemStatus: e,
              });
            }}
            data={[
              {
                name: "Бүгд",
                code: "",
                color: "violet",
              },
              ...itemStatus,
            ]}
          />
          <Segmented
            label="Захиалгын төрөл"
            value={filters?.supplierType || ""}
            onChange={(e: any) => {
              setFilters({
                ...filters,
                supplierType: e,
              });
            }}
            data={[
              {
                name: "Бүгд",
                code: "",
                color: "",
              },
              ...supplierTypes,
            ]}
          />
          <Segmented
            label="Буцаалтын төрөл"
            value={filters?.returnAllow || ""}
            onChange={(e: any) => {
              setFilters({
                ...filters,
                returnAllow: e,
              });
            }}
            data={[
              {
                name: "Бүгд",
                code: "",
                color: "grape",
              },
              {
                name: "Буцаалт авдаг",
                code: "true",
                color: "green",
              },
              {
                name: "Буцаалт авдаггүй",
                code: "false",
                color: "yellow",
              },
            ]}
          />
        </Flex>
        <Space h="xs" />
        <Grid>
          <Grid.Col span={{ xs: 12, sm: 6, md: 4 }} hidden={!ERP_GDS_WRH_SET.hasAccess}>
            <SelectFilter
              onSearchChange={(e) => {
                setWarehouseFilterDelay({
                  ...warehouseFilterDelay,
                  query: e,
                });
              }}
              label="Агуулах"
              value={`${filters?.warehouseId || ""}`}
              onChange={(e: any) => {
                setFilters({
                  ...filters,
                  warehouseId: e,
                });
              }}
              data={
                warehouseSelects?.map((item: any) => {
                  return {
                    name: item?.name || "",
                    code: item?.id || "",
                  };
                }) || []
              }
            />
          </Grid.Col>

          <Grid.Col span={{ xs: 12, sm: 6, md: 4 }} hidden={!ERP_GDS.isEdit}>
            <SelectFilter
              onSearchChange={(e) => {
                setItemTypeSelectsFilterDelay({
                  ...itemTypeFilterDelay,
                  query: e,
                });
              }}
              label="Нэр төрөл"
              value={filters?.itemTypeId || ("" as string)}
              onChange={(e: any) => {
                setFilters({
                  ...filters,
                  itemTypeId: e,
                  classificationId: null,
                  subClassificationId: null,
                  categoryId: null,
                  subCategoryId: null,
                });
                setClassificationFilter({
                  ...classificationFilter,
                  itemTypeId: e,
                  query: null,
                });
                setSubClassificationFilter({
                  ...subClassificationFilter,
                  itemTypeId: e,
                  query: null,
                });
                setCategoriesFilter({
                  ...categoriesFilter,
                  itemTypeId: e,
                  query: null,
                });
                setSubCategoriesFilter({
                  ...subCategoriesFilter,
                  itemTypeId: e,
                  query: null,
                });
              }}
              data={
                itemTypeSelects?.map((item: any) => {
                  return {
                    name: item.name,
                    code: item.id,
                  };
                }) || []
              }
            />
          </Grid.Col>
          <Grid.Col span={{ xs: 12, sm: 6, md: 4 }} hidden={!ERP_GDS.isEdit}>
            <SelectFilter
              onSearchChange={(e) => {
                setClassificationQueryDelay(e);
              }}
              disabled={!classificationFilter.itemTypeId}
              label="Ангилал"
              value={filters?.classificationId || ""}
              onChange={(e: any) => {
                setSubClassificationFilter({
                  ...subClassificationFilter,
                  parentId: e,
                });
                setFilters({
                  ...filters,
                  classificationId: e,
                  subClassificationId: null,
                });
              }}
              data={
                classificationSelects?.map((item: any) => {
                  return {
                    name: item.name,
                    code: item.id,
                  };
                }) || []
              }
            />
          </Grid.Col>
          <Grid.Col span={{ xs: 12, sm: 6, md: 4 }} hidden={!ERP_GDS.isEdit}>
            <SelectFilter
              onSearchChange={(e) => {
                setSubClassificationQueryDelay(e);
              }}
              disabled={!subClassificationFilter.itemTypeId || !subClassificationFilter.parentId}
              label="Дэд ангилал"
              value={filters?.subClassificationId || ""}
              onChange={(e: any) => {
                setFilters({
                  ...filters,
                  subClassificationId: e,
                });
              }}
              data={
                subClassificationSelects?.map((item: any) => {
                  return {
                    name: item.name,
                    code: item.id,
                  };
                }) || []
              }
            />
          </Grid.Col>
          <Grid.Col span={{ xs: 12, sm: 6, md: 4 }} hidden={!ERP_GDS.isEdit}>
            <SelectFilter
              onSearchChange={(e) => {
                setCategoriesQueryDelay(e);
              }}
              disabled={!categoriesFilter.itemTypeId}
              label="Категори"
              value={filters?.categoryId || ""}
              onChange={(e: any) => {
                setFilters({
                  ...filters,
                  categoryId: e,
                  subCategoryId: null,
                });
                setSubCategoriesFilter({
                  ...subCategoriesFilter,
                  parentId: e,
                });
              }}
              data={
                categoriesSelects?.map((item: any) => {
                  return {
                    name: item.name,
                    code: item.id,
                  };
                }) || []
              }
            />
          </Grid.Col>
          <Grid.Col span={{ xs: 12, sm: 6, md: 4 }} hidden={!ERP_GDS.isEdit}>
            <SelectFilter
              onSearchChange={(e) => setSubCategoriesQueryDelay(e)}
              disabled={!subCategoriesFilter.itemTypeId || !subCategoriesFilter.parentId}
              label="Дэд категори"
              value={filters?.subCategoryId || ""}
              onChange={(e: any) => {
                setFilters({
                  ...filters,
                  subCategoryId: e,
                });
              }}
              data={
                subCategoriesSelects?.map((item: any) => {
                  return {
                    name: item.name,
                    code: item.id,
                  };
                }) || []
              }
            />
          </Grid.Col>
          <Grid.Col span={{ xs: 12, sm: 6, md: 4 }} hidden={!ERP_GDS.isEdit}>
            <SelectFilter
              onSearchChange={(e) => setBrandQueryDelay(e)}
              label="Брэнд"
              value={filters?.brandId || ""}
              onChange={(e: any) => {
                setFilters({
                  ...filters,
                  brandId: e,
                });
              }}
              data={
                brandSelects?.map((item: any) => {
                  return {
                    name: item.name,
                    code: item.id,
                  };
                }) || []
              }
            />
          </Grid.Col>
          <Grid.Col span={{ xs: 12, sm: 6, md: 4 }} hidden={!ERP_GDS.isEdit}>
            <SelectFilter
              onSearchChange={(e) => setManufacturerQueryDelay(e)}
              label="Үйлдвэрлэгч"
              value={filters?.manufacturerId || ""}
              onChange={(e: any) => {
                setFilters({
                  ...filters,
                  manufacturerId: e,
                });
              }}
              data={
                manufacturerSelects?.map((item: any) => {
                  return {
                    name: item.name,
                    code: item.id,
                  };
                }) || []
              }
            />
          </Grid.Col>
          <Grid.Col span={{ xs: 12, sm: 6, md: 4 }} hidden={!ERP_GDS.isEdit}>
            <SelectFilter
              onSearchChange={(e) => setDistributorQueryDelay(e)}
              label="Дистрибьютер"
              value={filters?.distributorId || ""}
              onChange={(e: any) => {
                setFilters({
                  ...filters,
                  distributorId: e,
                });
              }}
              data={
                distributorSelects?.map((item: any) => {
                  return {
                    name: item.name,
                    code: item.id,
                  };
                }) || []
              }
            />
          </Grid.Col>
          <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
            <ActionIcon
              onClick={() => {
                onClearFilter();
              }}
              size={36}
              color=""
              variant="light">
              <IconFilter />
            </ActionIcon>
          </Grid.Col>
        </Grid>
      </div>
      <Space h={"md"} />
      {ERP_GDS.isView ? (
        <Table ref={refTable} name={`erp.goods.list`} columns={columns} filters={filters} loadData={(data) => GoodsApi.list(data!)} />
      ) : (
        ERP_GDS.accessDenied()
      )}

      <Modal
        centered
        title={false}
        opened={action[0] === "product_active" || action[0] === "product_inactive"}
        onClose={() => {
          setAction([]);
        }}
        padding={0}
        withCloseButton={false}>
        <ProductActiveForm
          action={action}
          reload={async () => {
            refTable.current?.reload();
          }}
          onCancel={() => {
            setAction([]);
          }}
        />
      </Modal>

      <Drawer
        position="right"
        size={"80rem"}
        opened={action[0] === "set_price"}
        onClose={() => setAction([])}
        title={false}
        withCloseButton={false}
        padding={0}>
        <Box p={"lg"} style={{ overflowY: "auto", height: "calc(100vh)", overflowX: "hidden" }}>
          <FormLayout title={"БАРААНЫ ҮНЭ ТОХИРГОО"} extra={[<CloseButton onClick={() => setAction([])} key={9} />]}>
            <ProductSetPriceForm onClose={() => setAction([])} action={action} reload={async () => refTable.current?.reload()} />
          </FormLayout>
        </Box>
      </Drawer>

      <Drawer
        position="right"
        size={"80rem"}
        opened={action[0] === "set_wherehouse"}
        onClose={() => setAction([])}
        title={false}
        withCloseButton={false}
        padding={0}>
        <Box p={"xs"} style={{ overflow: "auto", height: "100vh" }}>
          <WherehouseSetForm
            onClose={() => {
              setAction([]);
              dispatch(updateManagers([]));
            }}
            data={action[1]}
            reload={async () => refTable.current?.reload()}
          />
        </Box>
      </Drawer>

      <Modal
        size={"28rem"}
        title={false}
        opened={action[0] === "image" && action[1]?.image}
        onClose={() => setAction([])}
        padding={0}
        centered
        withCloseButton={false}>
        <Image src={action[1]?.image} />
      </Modal>
    </div>
  );
};

const useHeader = ({
  onClick,
  itemStatus,
  itemTypes,
  ERP_GDS_PRICE_SET,
  ERP_GDS_WRH_SET,
  ERP_GDS_STATUS,
  ERP_GDS,
  supplierTypes,
}: {
  onClick: (key: string, record: IItem | any) => void;
  itemStatus: IReference[];
  itemTypes: any[];
  ERP_GDS_PRICE_SET: any;
  ERP_GDS_WRH_SET: any;
  ERP_GDS_STATUS: any;
  ERP_GDS: any;
  supplierTypes: IReference[];
}): ColumnType<IItem | any>[] => [
  {
    title: "#",
    render: (_r, index) => <Text size="sm">{index + 1}</Text>,
    width: "30px",
  },
  {
    title: "Action",
    render: (record) => {
      return (
        <Flex gap={10} align="center">
          {record.variantStatus === "DRAFT" ? (
            <Tooltip position="bottom" label="Засварлах" hidden={!ERP_GDS.isEdit}>
              <ActionIcon color="" variant="light" onClick={() => onClick("edit", record)}>
                <IconEdit />
              </ActionIcon>
            </Tooltip>
          ) : (
            <>
              <Tooltip position="bottom" label="Дэлгэрэнгүй" hidden={!ERP_GDS.isView}>
                <ActionIcon color="" variant="light" onClick={() => onClick("edit", record)}>
                  <IconFileText />
                </ActionIcon>
              </Tooltip>
              <Tooltip position="bottom" label="Үнэ" hidden={!ERP_GDS_PRICE_SET.hasAccess}>
                <ActionIcon
                  color={record.isPriceSet ? "green" : "red"}
                  variant="light"
                  onClick={() => onClick("set_price", record)}
                  disabled={record.variantStatus === "DRAFT"}>
                  {record.isPriceSet ? <IconTags /> : <IconTagsOff />}
                </ActionIcon>
              </Tooltip>
              <Tooltip position="bottom" label={record.isWarehouseSet ? "Агуулахын үлдэгдэл" : "Агуулах тохируулах"} hidden={!ERP_GDS_WRH_SET.hasAccess}>
                <ActionIcon
                  color={record.isWarehouseSet ? "green" : "red"}
                  variant="light"
                  onClick={() => onClick("set_wherehouse", record)}
                  disabled={record?.supplierType === null || record.variantStatus === "DRAFT"}>
                  <IconBuildingWarehouse />
                </ActionIcon>
              </Tooltip>
              {record.variantStatus === "ACTIVE" ? (
                <Tooltip position="bottom" label={"Идэвхгүй болгох"} hidden={!ERP_GDS_STATUS.hasAccess}>
                  <ActionIcon
                    disabled={!record.isPriceSet || !record.isWarehouseSet}
                    color={"purple"}
                    variant="light"
                    onClick={() => onClick("product_inactive", record)}>
                    <IconCheck />
                  </ActionIcon>
                </Tooltip>
              ) : (
                <Tooltip position="bottom" label={"Идэвхжүүлэх"} hidden={!ERP_GDS_STATUS.hasAccess}>
                  <ActionIcon
                    disabled={!record.isPriceSet || !record.isWarehouseSet}
                    color={"gray"}
                    variant="light"
                    onClick={() => onClick("product_active", record)}>
                    <IconCheck />
                  </ActionIcon>
                </Tooltip>
              )}
            </>
          )}
        </Flex>
      );
    },
  },
  {
    title: "Зураг",
    dataIndex: "image",
    render: (record) => (
      <UnstyledButton onClick={() => onClick("image", record)}>
        <Avatar src={`${record.image ?? "-"}`} radius="xs">
          <IconPhoto />
        </Avatar>
      </UnstyledButton>
    ),
  },
  {
    title: "DeHub Код",
    sorter: true,
    dataIndex: "sku",
    render: (record) => (
      <Text size="sm" w="max-content">
        {record?.refCode ?? "-"}
      </Text>
    ),
  },
  {
    title: "SKU Код",
    sorter: true,
    dataIndex: "sku",
    render: (record) => (
      <Text size="sm" w="max-content">
        {record.skuCode ?? "-"}
      </Text>
    ),
  },
  {
    title: "Bar Код",
    sorter: true,
    dataIndex: "sku",
    render: (record) => (
      <Text size="sm" w="max-content">
        {record.barCode ?? "-"}
      </Text>
    ),
  },
  {
    title: "Барааны нэр",
    sorter: true,
    dataIndex: "name",
    render: (record) => (
      <Text w="max-content" size="sm">
        {`${record.nameMon}, ${
          Array.isArray(record?.optionValues) && record.optionValues.length > 0
            ? record.optionValues.map((item: { name: string }) => item.name).join(", ")
            : "-"
        }`}
      </Text>
    ),
  },
  {
    title: "Категори",
    sorter: true,
    dataIndex: "category",
    render: (record) => (
      <Text size="sm" w="max-content">
        {record?.category?.name ?? "-"}
      </Text>
    ),
  },
  {
    title: "Дэд категори",
    sorter: true,
    dataIndex: "subClassificationId",
    render: (record) => (
      <Text size="sm" w="max-content">
        {record?.subCategory?.name ?? "-"}
      </Text>
    ),
  },
  {
    title: "Статус",
    sorter: true,
    dataIndex: "variantStatus",
    render: (record) => {
      return (
        <Badge
          color={itemStatus.find((f: IReference) => f.code === record.variantStatus)?.color ?? "gray"}
          variant="outline"
          styles={{
            label: {
              overflow: "visible",
            },
          }}>
          {itemStatus.find((f: IReference) => f.code === record.variantStatus)?.name ?? (record.variantStatus || "-")}
        </Badge>
      );
    },
  },
  {
    title: "Захиалгын төрөл",
    sorter: true,
    dataIndex: "subClassificationId",
    render: (record) => (
      <Text w="max-content" size="sm">
        {supplierTypes?.find((f: IReference) => f.code === record.supplierType)?.name ?? "-"}
      </Text>
    ),
  },
];
