import { Avatar, Box, Button, Checkbox, CloseButton, Flex, Grid, Group, Input, NumberInput, Paper, Space, Tabs, Text } from "@mantine/core";
import { useForm, yupResolver } from "@mantine/form";
import { IconCheck, IconCurrencyTugrik, IconDeviceFloppy, IconPhoto, IconPlus } from "@tabler/icons-react";
import dayjs from "dayjs";
import { useState } from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { GoodsApi } from "../../apis";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../interfaces/IReference";
import { message } from "../../utils/message";
import { TierPrices } from "./price-group";
import { PriceQuantityList } from "./price-quantity";
import { SetAdditionalUnits } from "./set-additional-units";

const schema = yup.object().shape({
  variantId: yup.string().required("Заавал бөглөнө!").nullable(),
  standardPrice: yup.number().moreThan(0, "0 -с их утга оруулна уу.!").required("Заавал бөглөнө!").nullable(),
  tierPrices: yup
    .array()
    .of(
      yup.object().shape({
        tierNo: yup.number().min(0, "Заавал бөглөнө!").typeError("Заавал бөглөнө!").required("Заавал бөглөнө!").nullable(),
        price: yup.number().moreThan(0, "0 -с их утга оруулна уу.!").typeError("Заавал бөглөнө!").required("Заавал бөглөнө!").nullable(),
        startDate: yup.string().required("Заавал бөглөнө!").nullable(),
        endDate: yup.string().required("Заавал бөглөнө!").nullable(),
      }),
    )
    .min(0, "Заавал бөглөнө!")
    .required("Заавал бөглөнө!"),
  vatType: yup.string().required("Заавал бөглөнө!").typeError("Заавал бөглөнө!"),
  hasTax: yup.boolean().optional(),
  vatPercent: yup
    .number()
    .when("vatType", {
      is: "STANDARD_RATED",
      then: (schema) =>
        schema.moreThan(0, "0 -с их утга оруулна уу!").min(0.1, "бага даа 0.1 хувь").max(10, "Их дээ 10 хувь!").required("Заавал бөглөнө!").nullable(),
    })
    .nullable()
    .optional(),
  taxPercent: yup
    .number()
    .when("hasTax", {
      is: true,
      then: (schema) =>
        schema.moreThan(0, "0-с их утга оруулна уу!").min(0.1, "бага даа 0.1 хувь").max(2, "Их дээ 2 хувь!").required("Заавал бөглөнө!").nullable(),
    })
    .nullable()
    .optional(),
  quantityPrices: yup
    .array()
    .of(
      yup.object().shape({
        startDate: yup.string().nullable().required("Заавал бөглөнө!"),
        endDate: yup.string().nullable().required("Заавал бөглөнө!"),
        minQuantity: yup.number().moreThan(0, "0 -с их утга оруулна уу.!").typeError("Заавал бөглөнө!").nullable().required("Заавал бөглөнө!"),
        hasMaxLimit: yup.boolean(),
        maxQuantity: yup
          .number()
          .typeError("Заавал бөглөнө!")
          .when("hasMaxLimit", {
            is: true,
            then: (schema) => schema.moreThan(0, "0 -с их утга оруулна уу.!").typeError("Заавал бөглөнө!").nullable().required("Заавал бөглөнө!"),
          })
          .nullable()
          .optional(),
        salesPrice: yup.number().moreThan(0, "0 -с их утга оруулна уу.!").typeError("Заавал бөглөнө!").nullable().required("Заавал бөглөнө!"),
      }),
    )
    .optional(),
  currency: yup.string().optional().nullable(),
  unitPrices: yup
    .array()
    .of(
      yup.object().shape({
        variantId: yup.string().nullable().required("Заавал бөглөнө!"),
        startDate: yup.string().nullable().required("Заавал бөглөнө!"),
        endDate: yup.string().nullable().required("Заавал бөглөнө!"),
        tier1Price: yup.object().shape({
          isSet: yup.boolean(),
          value: yup
            .number()
            .nullable()
            .when("isSet", {
              is: true,
              then: (schema) => schema.moreThan(0, "0 -с их утга оруулна уу.!").typeError("Заавал бөглөнө!").nullable().required("Заавал бөглөнө!"),
            })
            .optional(),
        }),
        tier2Price: yup.object().shape({
          isSet: yup.boolean(),
          value: yup
            .number()
            .nullable()
            .when("isSet", {
              is: true,
              then: (schema) => schema.moreThan(0, "0 -с их утга оруулна уу.!").typeError("Заавал бөглөнө!").nullable().required("Заавал бөглөнө!"),
            })
            .optional(),
        }),
        tier3Price: yup.object().shape({
          isSet: yup.boolean(),
          value: yup
            .number()
            .nullable()
            .when("isSet", {
              is: true,
              then: (schema) => schema.moreThan(0, "0 -с их утга оруулна уу.!").typeError("Заавал бөглөнө!").nullable().required("Заавал бөглөнө!"),
            })
            .optional(),
        }),
        tier4Price: yup.object().shape({
          isSet: yup.boolean(),
          value: yup
            .number()
            .nullable()
            .when("isSet", {
              is: true,
              then: (schema) => schema.moreThan(0, "0 -с их утга оруулна уу.!").typeError("Заавал бөглөнө!").nullable().required("Заавал бөглөнө!"),
            })
            .optional(),
        }),
        tier5Price: yup.object().shape({
          isSet: yup.boolean(),
          value: yup
            .number()
            .nullable()
            .when("isSet", {
              is: true,
              then: (schema) => schema.moreThan(0, "0 -с их утга оруулна уу.!").typeError("Заавал бөглөнө!").nullable().required("Заавал бөглөнө!"),
            })
            .optional(),
        }),
        tier6Price: yup.object().shape({
          isSet: yup.boolean(),
          value: yup
            .number()
            .nullable()
            .when("isSet", {
              is: true,
              then: (schema) => schema.moreThan(0, "0 -с их утга оруулна уу.!").typeError("Заавал бөглөнө!").nullable().required("Заавал бөглөнө!"),
            })
            .optional(),
        }),
        tier7Price: yup.object().shape({
          isSet: yup.boolean(),
          value: yup
            .number()
            .nullable()
            .when("isSet", {
              is: true,
              then: (schema) => schema.moreThan(0, "0 -с их утга оруулна уу.!").typeError("Заавал бөглөнө!").nullable().required("Заавал бөглөнө!"),
            })
            .optional(),
        }),
        tier8Price: yup.object().shape({
          isSet: yup.boolean(),
          value: yup
            .number()
            .nullable()
            .when("isSet", {
              is: true,
              then: (schema) => schema.moreThan(0, "0 -с их утга оруулна уу.!").typeError("Заавал бөглөнө!").nullable().required("Заавал бөглөнө!"),
            })
            .optional(),
        }),
        tier9Price: yup.object().shape({
          isSet: yup.boolean(),
          value: yup
            .number()
            .nullable()
            .when("isSet", {
              is: true,
              then: (schema) => schema.moreThan(0, "0 -с их утга оруулна уу.!").typeError("Заавал бөглөнө!").nullable().required("Заавал бөглөнө!"),
            })
            .optional(),
        }),
        tier10Price: yup.object().shape({
          isSet: yup.boolean(),
          value: yup
            .number()
            .nullable()
            .when("isSet", {
              is: true,
              then: (schema) => schema.moreThan(0, "0 -с их утга оруулна уу.!").typeError("Заавал бөглөнө!").nullable().required("Заавал бөглөнө!"),
            })
            .optional(),
        }),
      }),
    )
    .optional(),
});

interface FormValues {
  standardPrice: number | undefined;
  vatType: string | undefined;
  vatPercent: number | undefined | null;
  variantId: string | undefined;
  currency: string | undefined;
  hasTax: boolean;
  taxPercent: number | undefined | null;
  tierPrices: {
    name: string | null;
    tierNo: number | undefined | null;
    price: number | undefined;
    startDate: string | undefined;
    endDate: string | undefined;
  }[];
  quantityPrices: {
    minQuantity: number | undefined | null;
    hasMaxLimit: boolean;
    maxQuantity: number | undefined | null;
    salesPrice: number | undefined | null;
    startDate: string | undefined;
    endDate: string | undefined;
    standardPrice?: number | undefined;
  }[];
  unitPrices: {
    variantId: string | undefined;
    startDate: string | undefined;
    endDate: string | undefined;
    tier1Price: {
      value: number | undefined | null;
      isSet: boolean;
    };
    tier2Price: {
      value: number | undefined | null;
      isSet: boolean;
    };
    tier3Price: {
      value: number | undefined | null;
      isSet: boolean;
    };
    tier4Price: {
      value: number | undefined | null;
      isSet: boolean;
    };
    tier5Price: {
      value: number | undefined | null;
      isSet: boolean;
    };
    tier6Price: {
      value: number | undefined | null;
      isSet: boolean;
    };
    tier7Price: {
      value: number | undefined | null;
      isSet: boolean;
    };
    tier8Price: {
      value: number | undefined | null;
      isSet: boolean;
    };
    tier9Price: {
      value: number | undefined | null;
      isSet: boolean;
    };
    tier10Price: {
      value: number | undefined | null;
      isSet: boolean;
    };
  }[];
  confirm: boolean;

  startDate: Date | any;
  endDate: Date | any;
}

export function ProductSetPriceForm({ onClose, action, reload }: { onClose: () => void; action: any[]; reload: () => void }) {
  const { vatTypes, tiers, currencies } = useSelector((state: { general: IGeneral }) => state.general);
  const data: any = action[1];
  const [loading, setLoading] = useState<boolean>(false);
  const [onChangeTab, setOnChangeTab] = useState<string>("1");

  const now = dayjs(new Date()).format();

  const form = useForm<FormValues>({
    validate: yupResolver(schema),
    initialValues: {
      startDate: new Date(now),
      endDate: new Date(now),
      variantId: data?.variantId,
      vatType: data?.vatType,
      vatPercent: data?.vatPercent,
      currency: data?.currency,
      standardPrice: data?.standardPrice || undefined,
      hasTax: data?.hasTax || false,
      taxPercent: data?.taxPercent,
      quantityPrices: data?.quantityPrices || [],
      unitPrices:
        data?.unitPrices?.map((item: any) => {
          return {
            ...item,
            unitName: data?.baseUnit?.name,
            currencyName: data?.currency,
            isSet: true,
            tier1Price: {
              value: item?.tier1Price || null,
              isSet: tiers.find((find: any) => find.tierNo === 1)?.isSet || false,
            },
            tier2Price: {
              value: item?.tier2Price || null,
              isSet: tiers.find((find: any) => find.tierNo === 2)?.isSet || false,
            },
            tier3Price: {
              value: item?.tier3Price || null,
              isSet: tiers.find((find: any) => find.tierNo === 3)?.isSet || false,
            },
            tier4Price: {
              value: item?.tier4Price || null,
              isSet: tiers.find((find: any) => find.tierNo === 4)?.isSet || false,
            },
            tier5Price: {
              value: item?.tier5Price || null,
              isSet: tiers.find((find: any) => find.tierNo === 5)?.isSet || false,
            },
            tier6Price: {
              value: item?.tier6Price || null,
              isSet: tiers.find((find: any) => find.tierNo === 6)?.isSet || false,
            },
            tier7Price: {
              value: item?.tier7Price || null,
              isSet: tiers.find((find: any) => find.tierNo === 7)?.isSet || false,
            },
            tier8Price: {
              value: item?.tier8Price || null,
              isSet: tiers.find((find: any) => find.tierNo === 8)?.isSet || false,
            },
            tier9Price: {
              value: item?.tier9Price || null,
              isSet: tiers.find((find: any) => find.tierNo === 9)?.isSet || false,
            },
            tier10Price: {
              value: item?.tier10Price || null,
              isSet: tiers.find((find: any) => find.tierNo === 10)?.isSet || false,
            },
          };
        }) || [],
      confirm: data?.confirm || false,
      tierPrices:
        tiers
          .filter((item: any) => item.isSet)
          ?.map((item: any) => {
            let find = data?.tierPrices?.find((item2: any) => item2.tierNo === item.tierNo);

            if (find)
              return {
                ...find,
                name: item?.name || "-",
                unitName: data?.baseUnit?.name || "-",
                currency: currencies.find((item: any) => item.code === data?.currency)?.name || "-",
              };
            else
              return {
                ...item,
                unitName: data?.baseUnit?.name || "-",
                currency: currencies.find((item: any) => item.code === data?.currency)?.name || "-",
              };
          }) || [],
    },
  });

  const onSubmit = async (values: any) => {
    setLoading(true);

    if (tiers.length > 0) {
      try {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        let res = await GoodsApi.price({
          variantId: values.variantId,
          vatType: values.vatType,
          vatPercent: values.vatPercent,
          currency: values.currency,
          standardPrice: values.standardPrice,
          hasTax: values.hasTax,
          taxPercent: values.taxPercent,
          tierPrices:
            values.tierPrices.map((item: any) => {
              return {
                tierNo: item.tierNo,
                price: item.price,
                startDate: item.startDate,
                endDate: item.endDate,
              };
            }) || [],
          quantityPrices:
            values?.quantityPrices?.map((item: any) => {
              return {
                minQuantity: item.minQuantity,
                hasMaxLimit: item.hasMaxLimit,
                maxQuantity: item.maxQuantity,
                salesPrice: item.salesPrice,
                startDate: item.startDate,
                endDate: item.endDate,
              };
            }) || [],
          unitPrices:
            values?.unitPrices?.map((item: any) => {
              return {
                variantId: item?.variantId || undefined,
                startDate: item?.startDate || undefined,
                endDate: item?.endDate || undefined,
                tier1Price: item?.tier1Price?.value || undefined,
                tier2Price: item?.tier2Price?.value || undefined,
                tier3Price: item?.tier3Price?.value || undefined,
                tier4Price: item?.tier4Price?.value || undefined,
                tier5Price: item?.tier5Price?.value || undefined,
                tier6Price: item?.tier6Price?.value || undefined,
                tier7Price: item?.tier7Price?.value || undefined,
                tier8Price: item?.tier8Price?.value || undefined,
                tier9Price: item?.tier9Price?.value || undefined,
                tier10Price: item?.tier10Price?.value || undefined,
              };
            }) || [],
          confirm: values.confirm,
        });
        message.success("Үйлдэл амжилттай.");
        setLoading(false);
        reload();
        onClose();
      } catch (error: any) {
        message.error(error.message || "Үйлдэл амжилтгүй!");
        setLoading(false);
      }
    } else message.error("Хүсэлт амжилтгүй.");
    setLoading(false);
  };

  return (
    <form
      onSubmit={form.onSubmit((values) => {
        return values;
      })}>
      <Box>
        <Group my={"md"} justify="space-between">
          <Text fw={600} fz={"lg"}>
            Ерөнхий мэдээлэл
          </Text>
          <Flex gap={"md"}>
            {!data?.isPriceSet && (
              <>
                <Button
                  type="submit"
                  loading={loading}
                  onClick={async () => {
                    const isValid = form.isValid();
                    if (isValid) {
                      onSubmit({
                        ...form.values,
                        confirm: false,
                      });
                    } else message.error("Талбарыг бүрэн бөглөнө үү.");
                  }}
                  leftSection={<IconDeviceFloppy size={20} />}
                  variant="outline">
                  Хадгалах
                </Button>
                <Button
                  type="submit"
                  loading={loading}
                  onClick={async () => {
                    const isValid = form.isValid();

                    if (isValid) {
                      onSubmit({
                        ...form.values,
                        confirm: true,
                      });
                    } else message.error("Талбарыг бүрэн бөглөнө үү.");
                  }}
                  leftSection={<IconCheck size={20} />}>
                  Батлах
                </Button>
              </>
            )}
          </Flex>
        </Group>

        <Grid>
          <Grid.Col span={{ xs: 12, sm: 12, md: 4 }}>
            <Box>
              <Flex gap={12}>
                <Paper withBorder>
                  <Avatar size={160} src={data?.image} radius="xs">
                    <IconPhoto />
                  </Avatar>
                </Paper>

                <Flex direction={"column"} gap={"sm"}>
                  <Flex gap={8}>
                    <Text c="gray.8" fw={600}>
                      SKU:
                    </Text>
                    <Text>{data?.skuCode || "-"}</Text>
                  </Flex>
                  <Flex gap={8}>
                    <Text c="gray.8" fw={600}>
                      Barcode:
                    </Text>
                    <Text>{data?.barCode || "-"}</Text>
                  </Flex>
                  <Flex gap={8}>
                    <Text c="gray.8" fw={600}>
                      DeHUB Code:
                    </Text>
                    <Text>{data?.refCode || "-"}</Text>
                  </Flex>
                  <Flex gap={8}>
                    <Text c="gray.8" fw={600}>
                      Нэр:
                    </Text>
                    <Text>{data?.nameMon || "-"}</Text>
                  </Flex>
                  <Flex gap={8}>
                    <Text c="gray.8" fw={600}>
                      Үнэ тохируулах тоо:
                    </Text>
                    <Text>{data?.baseUnit?.convertValue || "-"}</Text>
                  </Flex>
                </Flex>
              </Flex>
            </Box>
          </Grid.Col>
          <Grid.Col span={{ xs: 12, sm: 12, md: 8 }}>
            <Grid>
              <Grid.Col span={{ xs: 12, sm: 6 }}>
                <Input.Wrapper label="Үндсэн нэгж" required>
                  <Text c="gray.8" fw={600}>
                    {data?.baseUnit?.name || "-"}
                  </Text>
                </Input.Wrapper>
                <Space h={"lg"} />
                <Input.Wrapper label="Үнэ тооцох валют" required>
                  <Text c="gray.8" fw={600}>
                    {currencies.find((item: any) => item.code === data?.currency)?.name || "Төгрөг"}
                  </Text>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6 }}>
                <Input.Wrapper label="НӨАТ-ийн төрөл" required error={form.errors?.vatType}>
                  <Box p="sm">
                    {vatTypes.map((item: IReference, index: number) => {
                      return (
                        <Flex key={index} gap={12} align={"center"} h={"max-content"}>
                          <Checkbox
                            error={!!form.errors.vatType}
                            checked={form.values.vatType === item.code}
                            label={<Text>{item.name}</Text>}
                            onChange={() => form.setFieldValue("vatType", item.code)}
                            disabled={data?.isPriceSet}
                          />
                          {form.values.vatType === "STANDARD_RATED" && item.code === "STANDARD_RATED" && (
                            <NumberInput
                              max={10}
                              w={"160px"}
                              min={0}
                              placeholder="10"
                              rightSectionWidth={55}
                              rightSection={<Text size={"xs"}>| хувь</Text>}
                              size="xs"
                              {...form.getInputProps(`vatPercent`)}
                              disabled={data?.isPriceSet}
                            />
                          )}
                        </Flex>
                      );
                    })}
                  </Box>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6 }}>
                <Input.Wrapper label="НХАТ-тай эсэх" required>
                  <Flex direction="column" gap="sm">
                    <Flex align="center" gap={12}>
                      <Checkbox
                        checked={form.values.hasTax}
                        label="Тийм"
                        onChange={() => {
                          form.setFieldValue("hasTax", true);
                          form.setFieldValue("taxPercent", 0);
                        }}
                        disabled={data?.isPriceSet}
                      />
                      {form.values.hasTax && (
                        <NumberInput
                          max={10}
                          placeholder="НХАТ дүн оруулах"
                          size="xs"
                          min={0}
                          rightSectionWidth={55}
                          rightSection={<Text size={"xs"}>| хувь</Text>}
                          w={"170px"}
                          {...form.getInputProps(`taxPercent`)}
                          disabled={data?.isPriceSet}
                        />
                      )}
                    </Flex>
                    <Checkbox
                      checked={!form.values.hasTax}
                      label="Үгүй"
                      onChange={() => {
                        form.setFieldValue("hasTax", false);
                      }}
                    />
                  </Flex>
                </Input.Wrapper>
              </Grid.Col>
              <Grid.Col span={{ xs: 12, sm: 6 }}>
                <Input.Wrapper label="Борлуулах нэгж стандарт үнэ (НӨАТ-гүй)" required>
                  <NumberInput
                    min={0}
                    leftSection={<IconCurrencyTugrik size={18} stroke="1.5" color="gray" opacity={0.8} />}
                    placeholder="0.00"
                    rightSection={
                      <CloseButton mr={"6px"} onClick={() => form.setFieldValue(`standardPrice`, undefined)} variant="transparent" size={"xs"} color="gray.6" />
                    }
                    {...form.getInputProps(`standardPrice`)}
                    disabled={data?.isPriceSet}
                  />
                </Input.Wrapper>
              </Grid.Col>
            </Grid>
          </Grid.Col>

          <Grid.Col span={12}>
            <Tabs
              onChange={(e: string | null) => {
                if (e) setOnChangeTab(e);
              }}
              defaultValue={onChangeTab}>
              <Tabs.List>
                <Tabs.Tab value="1">Үнийн бүлэг</Tabs.Tab>
                <Tabs.Tab value="2">Тооноос хамаарах үнэ</Tabs.Tab>
                <Tabs.Tab value="3">Нэмэлт хэмжих нэгж</Tabs.Tab>
              </Tabs.List>
              <Tabs.Panel value="1">
                <Text my={"lg"} fw={600} fz="lg">
                  Барааны үнийн бүлэг
                </Text>
                <Box pb={"xl"} style={{ overflowX: "auto" }}>
                  <TierPrices form={form} />
                </Box>
              </Tabs.Panel>
              <Tabs.Panel value="2">
                <Group justify="apart">
                  <Text my={"lg"} fw={600} fz="lg">
                    Тооноос хамаарах үнэ
                  </Text>
                  <Button
                    onClick={() => {
                      form.setFieldValue("quantityPrices", [
                        ...form.values.quantityPrices,
                        {
                          startDate: undefined,
                          endDate: undefined,
                          hasMaxLimit: false,
                          maxQuantity: undefined,
                          minQuantity: undefined,
                          salesPrice: undefined,
                          standardPrice: form.values.standardPrice,
                        },
                      ]);
                    }}
                    variant="light"
                    size="xs"
                    leftSection={<IconPlus size={18} />}>
                    Мөр нэмэх
                  </Button>
                </Group>
                <Box pb={"xl"} style={{ overflowX: "auto" }}>
                  <PriceQuantityList form={form} action={action} />
                </Box>
              </Tabs.Panel>
              <Tabs.Panel value="3">
                <Box pb={"xl"}>
                  <SetAdditionalUnits form={form} data={action[1] ? action[1] : {}} />
                </Box>
              </Tabs.Panel>
            </Tabs>
          </Grid.Col>
        </Grid>
      </Box>
    </form>
  );
}
